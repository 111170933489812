// Permission Helpers
export const hasRole = (target) => roles => roles.some(permission => {
  let found = permission.role.indexOf(target) !== -1;
  return found;
});

export const isAdmin = hasRole('rad');
export const isOwner = hasRole('owner');
export const isManager = hasRole('manager');


// TODO: Create complex combined functions that check if the user is an admin,
// owner and a manager, or is a rad, sales or tech, etc
