import { createAsyncConstants } from '../../helpers/actions.js';

export const TICKETS_FETCH = createAsyncConstants('TICKETS_FETCH');
export const TICKETS_COUNT_FETCH = createAsyncConstants('TICKETS_COUNT_FETCH');

const helpdeskContansts = {
  TICKETS_FETCH,
  TICKETS_COUNT_FETCH,
}

export default helpdeskContansts;
