import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import TagManager from 'react-gtm-module'

const gtmId = 'GTM-WSDTM8N';
const trackingId = 'G-D4P9NGZJGV';

export const useAnalytics = (props) => {
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);
  const { loggedIn, user } = useSelector(state => state.authentication);

  const onFirstLoad = () => {
    try {
      TagManager.initialize({ gtmId: gtmId });
      setInitialized(true);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(onFirstLoad, []);

  const onLocationChange = () => {
    if (initialized) {
      // Track data here manually if we can't get it working in google GTM
    }
  };
  useEffect(onLocationChange, [initialized, location]);

  const onLoggedInChange = () => {
    if (user?.id) {
      window.dataLayer.push({"ovation_user_id": user.id})
    }
  };
  useEffect(onLoggedInChange, [loggedIn]);

  return TagManager;
};
