import { createTheme, alpha, adaptV4Theme } from '@mui/material/styles';

//const breakpoints = createBreakpoints({})

//createTheme(adaptV4Theme({
// A custom theme for this app
const theme =
   createTheme({
    palette: {
      primary: {
        main: '#5484c4',
      },
      secondary: {
        main: '#84c454'
      },
      error: {
        main: '#c45484',
      },
      background: {
        default: '#f0f0f0',
      },
      text: {
        primary: '#464646',
      }
    },
    /*
    overrides: {
      MuiSvgIcon: {
        root: {
          verticalAlign: 'middle',
          paddingRight: '4px'
        }
      },
      MuiGrid: {
        root: {
          "&.debug": {
            borderStyle: 'solid',
            borderWidth: '1px',
            borderColor: 'red',
          },
          "&.debug2": {
            borderStyle: 'dotted',
            borderWidth: '1px',
            borderColor: 'blue',
          },
          "&.debug-highlight": {
            borderStyle: 'solid',
            borderWidth: '2px',
            borderColor: 'green',
          },
          "&.password-form":{
            width: '75%'
          },
          "&.password-form-element":{
            width: '95%'
          },
          "&.react-Countdown": {
            backgroundColor: '#85be39',
            marginBottom: '12px',
            padding: '6px',
            color: 'white',
            "& .Next-Update": {
              paddingBottom: '4px',
            }
          }
        }
      },
      MuiAvatar: {
        root: {
          width: 'auto',
          height: 'auto',
        }
      },
      MuiCardMedia: {
        root: {
          display: 'flex',
          width: '151px',
        },
        img: {
          height: '75px',
        }
      },
      MuiCardContent: {
        root: {
          flex: '1 0 auto',
        }
      },
      MuiTypography: {
        root: {
          "&.text-highlighter": {
            backgroundColor: "#f0f0f0",
            borderLeftStyle: 'solid',
            borderColor: '#556cd6',
            padding: '8px',
          },
          "&.Expired": {
            color: "red",
          },
          "&.Active": {
            color: "green",
          },
        }
      },
      MuiListItem: {
        root: {
          "&.text-highlighter": {
            backgroundColor: "#f0f0f0",
            borderLeftStyle: 'solid',
            borderColor: '#556cd6',
          }
        }
      },
      MuiFormControl: {
        root: {
          "& .companyName": {

          },
          "&.User-edit": {
            width: '100%',
            margin: '4px 8px 4px 8px'
          },
          "&.control-button-group": {
            alignItems: 'flex-end',
            paddingRight: '24px'
          }
        },
      },
      MuiAppBar: {
        root: {
          flexGrow: 1,
          padding: '4px',
          margin: '0px',
          "& .AppMenu": {
            marginRight: "4px",
          },
          "& .OvationTitle": {
            flexGrow: 1,
            display: 'none',
            [breakpoints.up('sm')]:{
              display: 'block',
            }
          },
          "& .CompanySelect": {
            position: 'relative',
            borderRadius: '8px',
            backgroundColor: alpha("#f0f0f0", 0.15),
            '&:hover':{
              backgroundColor: alpha("#f0f0f0", 0.25),
            },
            marginLeft: 0,
            [breakpoints.up('sm')]:{
              marginLeft: '4px',
              width: 'auto',
            },
          },
          "& .CompanySelectControl": {
            backgroundColor: '#f0f0f0',
          },
          "& .MuiAvatar-circular": {
            root: {
            }
          }
        }
      },
      MuiPaper: {
        root: {
          "&.page404": {
            position: 'absolute',
            textAlign: 'center',
            top: 'calc(50% - 150px)', left: 'calc(50% - 250px)',
            width: '500px', height: '333px',
            backgroundImage: "url('images/404dog.jpeg')"
          },
          "&.LoginForm": {
            padding: '4px',
            margin: '24px',
            boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
            backgroundColor: "#f0f0f0",
            "&.hover": {
              boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)"
            },
            "& .FormTitle": {
              marginLeft: '12px',
            },
            "& .MuiTextField-root": {
              marginLeft: '12px',
              marginRight: '12px',
            },
            "& .UserActionButtons": {
              textAlign: 'right',
              "& .MuiButtonBase-root": {
                marginLeft: '12px',
                marginRight: '12px',
              }
            },
            "& .LoginInfo": {
              padding: '24px',
            }
          },
          "&.ContactInfo": {
            padding: '4px',
            margin: '24px',
            boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
            backgroundColor: '#f0f0f0',
          },
          "& .PrivacyInfoBox": {
            overflow: 'scroll',
            display: 'block',
            position: 'absolute',
            top: '10%',
            left: '10%',
          },
          "& .PrivacyInfoText": {
            margin: '48px',
            padding: '4px',
            width: '80%',
          },
          "&.Edit-user": {
            padding: '4px',
            margin: '24px',
            boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
            backgroundColor: 'white',
          }
        }
      },
    }
    */
  });

export default theme;
