import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';

import { BrowserRouter } from 'react-router-dom';

import { store } from './helpers';
import App from './App';
import { ErrorBoundry } from './components';

render(
  <BrowserRouter>
    <Provider store={store}>
      <ErrorBoundry errorMessage="Unable to connect to API">
        <App />
      </ErrorBoundry>
    </Provider>
  </BrowserRouter>,
  document.getElementById('root')
);
