import React from 'react';
import {
  Card,
  CardActions,
  CardContent,
  Button,
  Typography
} from '@mui/material';
import UserList from '../../hoc/UserList';


let users = [
  {
    name: 'cwenman',
    displayName: 'Courtney Wenman',
    email: 'cwenman@ovationnetworks.com'
  },
  {
    name: 'agoodall',
    displayName: 'Aaron Goodall',
    email: 'agoodall@ovationnetworks.com'
  },
  {
    name: 'kcowman',
    displayName: 'Kyle Cowman',
    email: 'kcowman@ovationnetworks.com'
  },
  {
    name: 'shoover',
    displayName: 'Steve Hoover',
    email: 'shoover@ovationnetworks.com'
  }

];


export default function Admin() {

  return (
    <Card className={'Admin-Card'} variant="outlined">
      <CardContent>
        <Typography className={'Admin-Title'} color="textSecondary" gutterBottom>
          Admin
        </Typography>
        <Typography variant="h5" component="h2">
          Something
        </Typography>
        <Typography variant="body2" component="p">
          Something here.
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small">Nav Buttons</Button>
      </CardActions>
      <UserList isLoading="false" users={users} />
    </Card>
  );
}
