import { feathersClient as feathers } from '../../helpers/service';

const fetchCompanies = async () => {
  const query = {
    query: {
      companyType: { $gte: 7 },
      $select: [ 'companyId', 'companyName' ],
      $sort: { companyName: 1 }
    }
  };

  const companies = await feathers.service('companies').find(query);
  return companies.data
}

export const companiesServices = {
  fetchCompanies,
};
