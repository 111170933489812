import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { authenticationActions } from '../../redux/actions';

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const {loggedIn, user} = useSelector(state => state.authentication);
  const dispatch = useDispatch();

  const authenticate = () => {
    if (loggedIn) {
      dispatch(authenticationActions.reauthRequest());
    }
  };
  useEffect(authenticate, [loggedIn]);

  return (
    <Route {...rest} render={props => (
      loggedIn ? <Component {...props} />
               : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
    )} />
  );
}

export default PrivateRoute;
