import React from 'react';
import PropTypes from 'prop-types';
var Icon = require('./Icon');

class Button extends React.Component{
  render(){
    var that = this;

    function getClass(){
      var prefix = 'react-Button';
      var classes =  [];

      // add any passed in classes
      if(that.props.className){
        classes.push(that.props.className);
      }

      pushClass('reset');

      function pushClass(classToPush){
        classes.push(prefix + '-' + classToPush);
      }

      if(that.props.isCircle){
        pushClass('circle-button');
      }
      if(that.props.isChromeless){
        pushClass('chromeless')
      }
      if(that.props.isFillWidth){
        pushClass('fill-width');
      }
      if(that.props.color){
        classes.push(that.props.color);
      }

      return classes.join(' ');
    }

    function getIcon(icon){
      if(icon){
        return (
          <Icon icon={icon} />
        );
      }
    }
  
    return(
      <button
        disabled={this.props.disabled || false}
        className={getClass()}
        onClick={this.props.onClick}
      >
        {getIcon(this.props.icon)}
        {this.props.text}
      </button>
    );
  }
};

Button.propTypes = {
    className: PropTypes.string, // (optional) extra class names to add
    disabled: PropTypes.bool, // (optional) button is disabled
    isCircle: PropTypes.bool, // (optional) button renders as a circle
    isFillWidth: PropTypes.bool, // (optional) button takes all h-space
    isChromeless: PropTypes.bool, // (optional) button has no background
    color: PropTypes.string, // (optional) set the color to a preset
    icon: PropTypes.string, // (optional) icon preset
    text: PropTypes.string, // (optional) button text
    onClick: PropTypes.func // (optional) click handler function
}

export default Button;
