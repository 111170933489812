// Core
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { compose } from 'redux';
import { Redirect, useParams } from 'react-router-dom';

// Material-UI
import { alpha } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import LinearProgress from '@mui/material/LinearProgress' ;

// Selectors (from store)
import {
  createErrorMessageSelector,
  createLoadingSelector
} from '../../../helpers/selectors';

// Components
import ErrorBoundry from '../../../components/ErrorBoundry';
import Section from '../../../components/Section';
import CompanyMenu from  '../../../components/CompanyMenu';
import CompanyInfo from '../../../components/CompanyInfo';
import TicketList from '../../../components/ticketList';

// Actions & Helpers
import {
  companyActions,
  helpdeskActions } from '../../../redux/actions';
import { store } from '../../../helpers'

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
    marginBottom: '100px',
  },
}));

function CompanyCustomers(props) {
  const dispatch = useDispatch();
  const classes = useStyles();

  // Pull Router Params
  const { companyId } = useParams();

  // Component State
  const [ selectedCompany, setSelectedCompany ] = useState({});

  // Redux Store
  const { helpdesk } = useSelector(state => state);
  const company = useSelector(state => state.company);

  const loading = {
    company: useSelector(createLoadingSelector(['COMPANY_FETCH'])),
    tickets: useSelector(createLoadingSelector(['TICKETS_FETCH'])),
  }
  const errorMessage = {
    company: useSelector(createErrorMessageSelector(['COMPANY_FETCH'])),
    tickets: useSelector(createErrorMessageSelector(['TICKETS_FETCH'])),
  }

  // Effects
  const companyIdUpdate = () => {
    if (companyId) {
      dispatch(companyActions.companyFetchRequest(companyId));
      dispatch(helpdeskActions.ticketsFetchRequest(companyId));
    }
  };
  useEffect(companyIdUpdate, [companyId]);

  const companyUpdate = () => {
    if (company) {
      setSelectedCompany(company);
    }
  };
  useEffect(companyUpdate, [company]);

  if ( errorMessage.company ) {
    return (
      <Redirect to={{ pathname: '/home', state: { from: props.location } }} />
    )
  }

  //<CircularProgress className={classes.loading} />
  if ( !company || loading.company ) {
    return (
      <Container maxWidth="lg">
        <div className={classes.root}>
          <Grid container direction="column" justifyContent="center" alignItems="center" alignContent="center">
            <Grid item>
              <LinearProgress />
            </Grid>
          </Grid>
        </div>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg">
      <div className={classes.root}>
        <ErrorBoundry errorMessage={errorMessage.company}>
          <Grid container spacing={3}>
            <Section title={"Company Info"} xs={12}>
              <CompanyInfo
                companyId={companyId}
                displayName={company.companyPropname}
                address1={company.companyAddress1}
                address2={company.companyAddress2}
                cityname={company.cityName}
                statename={company.companyStatenew}
                zip={company.companyZip}
                phone1={company.companyPhone1}
                phone2={company.companyPhone2}
                fax={company.companyFax}
              />
            </Section>
            <CompanyMenu companyId={companyId} />
            <Section title={"Tickets"} xs={12}>
              <TicketList
                companyId={companyId}
                tickets={((helpdesk || {}).tickets || {}).data}
                isLoading = {loading.tickets}
              />
            </Section>
          </Grid>
        </ErrorBoundry>
      </div>
    </Container>
  )
}

export default CompanyCustomers

