import React, { useState, useEffect } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import theme from '../theme';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import Box from '@mui/material/Box';
// import config from '../config';

import { alertActions } from '../redux/actions';
import PrivateRoute from '../components/PrivateRoute';
import ErrorBoundry from '../components/ErrorBoundry';

import AlertSnackbar from '../components/AlertSnackbar';

// Pages
import View from '../views/View';
import About from '../views/about';
import Admin from '../views/admin';
import Company from '../views/company';
import CompanyNetwork from '../views/company/network';
import CompanyCustomers from '../views/company/customers';
import CompanyEquipment from '../views/company/equipment';
import Users from '../views/users';
import Error404 from '../views/error404';
import Home from '../views/home';
import Login from '../views/login'
import Logout from '../views/logout';
import User from '../views/user';
import CreateUser from '../views/user/CreateUser';
import VerifySignup from '../views/user/VerifySignup';
import ResetPassword from '../views/user/ResetPassword';
import Footer from '../views/Footer';

import { useAnalytics } from '../helpers/analytics';

const App = (props) => {
  const dispatch = useDispatch();
  const analytics = useAnalytics();

  //const alert = useSelector(state => state?.alert);

  const onRouteChanged = () => {
    dispatch(alertActions.clear());
  }

  /*
  const handleAlertClose = () => {
    dispatch(alertActions.clear());
  }
  */

  const SlideTransition = (props) => (
    <Slide {...props} direction="up" />
  )

  return (
    <Box>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap" />
            <div className="container">
                <ErrorBoundry errorMessage='Failed to load view'>
                    <View />
                    <Switch>
                        <Route exact path="/login" component={Login} />
                        <Route exact path='/verify/:token' component={VerifySignup} />
                        <Route exact path='/reset/:token' component={ResetPassword} />

                        <PrivateRoute exact path="/home" component={Home} />
                        <PrivateRoute exact path="/about" component={About} />
                        <PrivateRoute exact path="/logout" component={Logout} />
                        <PrivateRoute exact path="/company/:companyId" component={Company} />
                        <PrivateRoute exact path="/company/:companyId/overview" component={Company} />
                        <PrivateRoute exact path="/company/:companyId/network" component={CompanyNetwork} />
                        <PrivateRoute exact path="/company/:companyId/customers" component={CompanyCustomers} />
                        <PrivateRoute exact path="/company/:companyId/equipment" component={CompanyEquipment} />
                        <PrivateRoute exact path="/user/:userId" component={User} />
                        <PrivateRoute exact path='/user/' component={CreateUser} />
                        <PrivateRoute exact path='/users/' component={Users} />
                        <PrivateRoute exact path="/admin" component={Admin} />
                        <PrivateRoute exact path="/" component={Home} />
                        <PrivateRoute path="*" component={Error404} exact />
                      </Switch>
                      <Footer />
                </ErrorBoundry>
                <AlertSnackbar />
            </div>
        </ThemeProvider>
        </StyledEngineProvider>
    </Box>
  );
};

/*
                <AlertSnackbar type={alert?.type} message={alert?.message} action={alert?.action} onClose={handleAlertClose} />
                <Snackbar
                    open={showAlert}
                    onClose={handleClose}
                    TransitionComponent={SlideTransition}
                    message={alert.message}
                />
                */
export default App;
