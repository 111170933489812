import React, { useState } from "react";

import theme from '../../theme';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import { styled } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import ListItem from '@mui/material/ListItem';


const PaddedBox = styled(Box)( ({theme}) => ({
  margin: theme.spacing(2),
}));

const CompanyLogo = styled('img')( ({theme}) => ({
  height: 'auto',
  width: 'auto',
  margin: 'auto',
  display: 'block',
}));

const Content = styled('div')( ({theme}) => ({
  margin: '5px, auto, 5px, auto',
}));

const Title = styled('div')( ({theme}) => ({
  fontSize: '6px',
  fontWeight: 'bold',
  marginTop: '5px',
}));

const AccessCode = styled('p')( ({theme}) => ({
  marginLeft: '20px',
  marginTop: '0px',
}));

const CutsheetTable = styled('table')( ({theme}) => ({
  width: '100%',
  '& td': {
    width: '500px',
  }
}));

const BulletList = styled('ul')( ({theme}) => ({
  margin: '0px !important',
}));

const AccessCodeCutsheet = ({companyId = '0', accessCode = "yourAccessCode"}) => {
  return (
    <PaddedBox>
      <Card>
        <CardMedia
          component="img"
          height="75"
          sx={{
            width: 'auto',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: '16px',
          }}
          image={'https://captive.ovatn.net/logos/' + companyId}
        />
        <CardContent>
          <Box display="flex" alignItems="center" justifyContent="center">
            <Typography color="text.secondary" gutterBottom>
              Access Code
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" justifyContent="center">
            <Typography sx={{ marginLeft: '8px' }} gutterBottom variant="h5" component="div">
              {accessCode}
            </Typography>
          </Box>

          <Typography variant="subtitle1" color="text.secondary" sx={{ marginBottom: '0px' }}>
            Getting Started...
          </Typography>
          <Typography variant="body" color="text.secondary" gutterBottom>
            <BulletList>
              <li>On your wireless device, scan for the properties network and connect</li>
              <li>If a login page does not automatically load, open a web browser
                  (chrome, edge, safari, firefox) and navigate to <i>www.usa.com</i>
              </li>
              <li>Once on the login page, enter the access code from above and press 
                  then <b>Connect</b> button
              </li>
            </BulletList>
          </Typography>
          <Typography sx={{ marginTop: '4px' }}variant="caption" display="block" color="text.secondary">
            For help or support call (877) 275-9444
          </Typography>
        </CardContent>
      </Card>
    </PaddedBox>
  );
};

export default AccessCodeCutsheet; 



