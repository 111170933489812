import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';

import { alertActions } from '../redux/actions';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const AlertSnackbar = (props) => {
  const dispatch = useDispatch();

  const alert = useSelector(state => state?.alert);
  const [ open, setOpen ] = useState(false);

  const alertChange = () => {
    if (alert?.message) { setOpen(true); }
    else { setOpen(false); }
  };
  useEffect(alertChange, [alert]);

  const handleClose = () => {
    dispatch(alertActions.clear());
    setOpen(false);
  }

  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      <Snackbar open={open} autoHideDuration={9000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          {alert?.message || ''}
        </Alert>
      </Snackbar>
    </Stack>
  );
};


/*
      <Alert severity="error">This is an error message!</Alert>
      <Alert severity="warning">This is a warning message!</Alert>
      <Alert severity="info">This is an information message!</Alert>
      <Alert severity="success">This is a success message!</Alert>
*/

export default AlertSnackbar;
