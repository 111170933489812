import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';

import { DataGrid } from '@mui/x-data-grid';

import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import FilterListIcon from '@mui/icons-material/FilterList';
import LinearProgress from '@mui/material/LinearProgress';
import TextField from '@mui/material/TextField';
import Hidden from '@mui/material/Hidden';

import {
  createErrorMessageSelector,
  createLoadingSelector
} from '../../helpers/selectors';

import DatePickerButton from '../DatePickerButton';

import { DateRangePicker, DateRange } from "materialui-daterange-picker";

// Components
import Section from '../../components/Section';
import ErrorBoundry from '../../components/ErrorBoundry';

import {
  helpdeskActions,
} from '../../redux/actions';


const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(2),
  },
}));

const useToolbarStyles = makeStyles((theme) => ({
  root: {
  },
  title: {
    flex: '1 1 100%',
  },
}));

const TicketsToolbar = (props) => {
  const dispatch = useDispatch();
  const classes = useToolbarStyles();
  const history = useHistory();

  const filterOptions = [
    {label: 'All', value: 'all'},
    {label: 'Open', value: 'open'},
    {label: 'Closed', value: 'closed'},
  ]

  const [anchorEl, setAnchorEl] = useState(null);
  const [filter, setFilter] = useState("all");

  const handleFilterMenuOpen = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const handleFilterMenuClose = () => {
    setAnchorEl(null);
  };

  const handleFilterItemClick = (e, option) => {
    setFilter(option.value);
    setAnchorEl(null);
    props.onSearchOptionsChange({filter: option.value})
  }

  const handleFilterChange = (e) => { }

  return (
    <Toolbar className={classes.root}>
      <Typography
        className={classes.title}
        variant="h6"
        id="ticketTableTitle"
        component="div"
      >All Tickets</Typography>
      <Hidden xsUp >
      <TextField
        id="ticketsSearch"
        variant="standard"
        value={props.searchValue}
        onChange={props.onSearchChange}
        placeholder="Search..."
        hidden
      />
      </Hidden>
      <Tooltip title="Filter tickets">
        <IconButton aria-label="filter list" onClick={handleFilterMenuOpen} size="large">
          <FilterListIcon />
        </IconButton>
      </Tooltip>
      <Menu
        id="filter-menu"
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right', marginTop: '10px' }}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right', marginTop: '10px' }}
        open={Boolean(anchorEl)}
        onClose={handleFilterMenuClose}
      >
        {filterOptions.map((option, index) => (
          <MenuItem
            key={index}
            selected={option.value === filter}
            onClick={(e) => handleFilterItemClick(e, option)}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
      <DatePickerButton
        onChange={props.onSearchOptionsChange}
      />
    </Toolbar>
  );

};


//{ field: 'ticketSummary', headerName: 'Cause of the Call', flex: 1, valueFormatter: (value) => value?.lookup_value, },
const headers = [
  { field: 'itemId', headerName: 'Ticket #', width: 110 },
  { field: 'itemRequestorRoom', headerName: 'Room #', width: 110},
  { field: 'ticketOpenCode', headerName: 'Open Code', flex: 1, sortable: false, valueGetter: (field) => field?.value?.lookup_value?.split('|')[0], },
  { field: 'ticketCauseCode', headerName: 'Cause Code', flex: 1, sortable: false, valueGetter: (field) => field?.value?.lookup_value?.split('|')[0], },
  //{ field: 'ticketResolutionCode', headerName: 'Resolution Code', flex: .6, valueGetter: (field) => field?.value?.lookup_value?.split('|')[0], },
  { field: 'ticketStatusCode', headerName: 'Status Code', flex: .5, sortable: false, valueGetter: (field) => field?.value?.lookup_value?.split('|')[0], },
  { field: 'itemModified', headerName: 'Last Updated', width: 200, valueFormatter: (field) => new Date(field?.value).toLocaleString('en-us') },
]

function TicketList(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();

  // Local const component state
  const companyId = props.companyId;

  // State
  const [search, setSearch] = useState(null);
  const [searchOptions, setSearchOptions] = useState(null)
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sort, setSort] = useState('itemId');
  const [order, setOrder] = useState(0);


  // Selectors
  const loading = {
    tickets: useSelector(createLoadingSelector(['TICKETS_FETCH'])),
    total: useSelector(createLoadingSelector(['TICKETS_COUNT_FETCH'])),
  }
  const errorMessage = {
    tickets: useSelector(createErrorMessageSelector(['TICKETS_FETCH'])),
    total: useSelector(createErrorMessageSelector(['TICKETS_COUNT_FETCH'])),
  }

  const tickets = useSelector(state => state?.helpdesk?.tickets);
  const total = useSelector(state => state?.helpdesk?.total);

  // On first load, only runs once
  const onFirstLoad = () => {
    //console.log("first helpdesk tickets fetch", companyId, search, searchOptions, page, rowsPerPage);
    dispatch(helpdeskActions.ticketsCountFetchRequest(companyId, { search: search, filter: searchOptions }));
    dispatch(helpdeskActions.ticketsFetchRequest(companyId, { search: search, filter: searchOptions, page: page, size: rowsPerPage }));
  };
  useEffect(onFirstLoad, [])

  // Effects
  const onTicketsSearchChange = () => {
    setPage(0);
    dispatch(helpdeskActions.ticketsCountFetchRequest(companyId, { search: search, filter: searchOptions }));
    dispatch(helpdeskActions.ticketsFetchRequest(companyId, { search: search, filter: searchOptions, page: 0, size: rowsPerPage }));
  };
  useEffect(onTicketsSearchChange, [search, searchOptions]);

  const onSortModelChange = () => {
    dispatch(helpdeskActions.ticketsCountFetchRequest(companyId, { search: search, filter: searchOptions }));
    dispatch(helpdeskActions.ticketsFetchRequest(companyId, { search: search, filter: searchOptions, sort: sort, order: order, page: page, size: rowsPerPage  }));
  }
  useEffect(onSortModelChange, [sort, order]);

  const handleChangePage = (e) => {
    setPage(e);
    dispatch(helpdeskActions.ticketsCountFetchRequest(companyId, { search: search, filter: searchOptions }));
    dispatch(helpdeskActions.ticketsFetchRequest(companyId, { search: search, filter: searchOptions, page: e, size: rowsPerPage }));
  };

  const handleChangePageSize = (e) => {
    //setRowsPerPage(e.rowsPerPage); // TODO: not confirmed
    //dispatch(helpdeskActions.ticketsFetchRequest(companyId, search, searchOptions, page, rowsPerPage));
  };

  const handleSortModelChange = (newModel) => {
    if ( newModel && Array.isArray(newModel) && newModel.length > 0 ) {
      setSort(newModel[0]['field']);
      setOrder(newModel[0]['sort'] === 'asc' ? 1 : 0);
    }
  };

  const handleDoubleClick = (e) => {
    //history.push('/user/'+ e.id);
  };

  const handleTicketsSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleTicketsSearchOptionsChange = (options) => {
    setSearchOptions({...searchOptions, ...options});
  };


  return (
    <div className={classes.root}>
      <ErrorBoundry errorMessage={errorMessage.tickets}>
        <Grid container direction="column" spacing={3}>
          <Grid item>
            <TicketsToolbar
              searchValue={search}
              onSearchChange={handleTicketsSearchChange}
              onSearchOptionsChange={handleTicketsSearchOptionsChange}
            />
          </Grid>
          <Grid item>
            <DataGrid
              loading={loading.tickets}
              autoHeight
              disableColumnMenu
              paginationMode="server"
              filterMode="server"
              sortingMode="server"
              sortModel={[ { field: sort, sort: order ? 'asc' : 'desc' } ]}
              onSortModelChange={handleSortModelChange}
              columns={headers}
              rows={tickets}
              rowCount={total ?? 0}
              page={page}
              pageSize={rowsPerPage}
              rowsPerPageOptions={[rowsPerPage]}
              onPageChange={handleChangePage}
              onPageSizeChange={handleChangePageSize}
              onRowDoubleClick={handleDoubleClick}
            />
          </Grid>
        </Grid>
      </ErrorBoundry>
    </div>
  )
}

export default TicketList;
