import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';

const useStyles = makeStyles((theme) => ({
  root: {},
  valueContainer: {
    marginTop: theme.spacing(.5),
    marginBottom: theme.spacing(.5),
    textAlign: 'right',
  },
}));

const ListItemKeyValue = (props) => {
  const classes = useStyles();

  const showToolTip = props.tooltip ? {} : {open: false};

  return (
    <Tooltip title={props.tooltip || ''} {...showToolTip}>
      <ListItem className={classes.root} alignItems="flex-start">
        <ListItemText
          primary={props.primary}
          secondary={props.secondary}
        />
        <div className={classes.valueContainer}>
          <Typography
            variant="body2"
            className={props.valueClassName}
          >
            {props.value}
          </Typography>
        </div>
        {props.children}
      </ListItem>
    </Tooltip>
  )
};

export default ListItemKeyValue;
