import { call, put, throttle, takeLatest, delay, takeEvery } from 'redux-saga/effects';
import { createAction } from '../../helpers/actions';
import { serviceCall } from '../../helpers/effects';
import {
  USERS_FETCH,
  USERS_COUNT_FETCH,
} from '../constants';
import { usersServices } from '../services';

import { alertActions } from './';

export const usersActions = {
  usersFetchRequest: (options) => createAction(USERS_FETCH.REQUEST, { options: options }),
  usersFetchSuccess: (users) => createAction(USERS_FETCH.SUCCESS, { users: users }),
  usersFetchFailure: (errorMessage) => createAction(USERS_FETCH.FAILURE, { errorMessage: errorMessage }),

  usersCountFetchRequest: (options) => createAction(USERS_COUNT_FETCH.REQUEST, { options: options }),
  usersCountFetchSuccess: (total) => createAction(USERS_COUNT_FETCH.SUCCESS, { total: total }),
  usersCountFetchFailure: (errorMessage) => createAction(USERS_COUNT_FETCH.FAILURE, { errorMessage: errorMessage }),
}

export function* fetchUsers(action) {
  try {
    //yield delay(1000);
    const users = yield serviceCall(usersServices.fetchUsers, action.options);
    yield put(usersActions.usersFetchSuccess(users));
  } catch (e) {
    yield put(usersActions.usersFetchFailure(e.message));
    yield put(alertActions.error(`Failed to fetch users: ${e.message}`, action.type));
  }
}

export function* fetchUsersCount(action) {
  try {
    //yield delay(1000);
    const total = yield serviceCall(usersServices.fetchUsersCount, action.options);
    yield put(usersActions.usersCountFetchSuccess(total));
  } catch (e) {
    yield put(usersActions.usersCountFetchFailure(e.message));
    yield put(alertActions.error(`Failed to fetch users count: ${e.message}`, action.type));
  }
}

export const usersSagas = [
  throttle(1000, USERS_FETCH.REQUEST, fetchUsers),
  throttle(1000, USERS_COUNT_FETCH.REQUEST, fetchUsersCount)
]

