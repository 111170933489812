import { createAsyncConstants } from '../../helpers/actions.js';

export const USER_FETCH = createAsyncConstants('USER_FETCH');
export const USER_UPDATE = createAsyncConstants('USER_UPDATE');
export const USER_CREATE = createAsyncConstants('USER_CREATE');
export const USER_REMOVE = createAsyncConstants('USER_REMOVE');
export const USER_ROLES_FETCH = createAsyncConstants('USER_ROLES_FETCH');
export const USER_ROLES_COUNT_FETCH = createAsyncConstants('USER_ROLES_COUNT_FETCH');
export const USER_ROLES_ADD = createAsyncConstants('USER_ROLES_ADD');
export const USER_ROLES_REMOVE = createAsyncConstants('USER_ROLES_REMOVE');
export const USER_CLEAR_ACTIVE = 'USER_CLEAR_ACTIVE';

const userContansts = {
  USER_FETCH,
  USER_UPDATE,
  USER_CREATE,
  USER_REMOVE,
  USER_ROLES_FETCH,
  USER_ROLES_COUNT_FETCH,
  USER_ROLES_ADD,
  USER_ROLES_REMOVE,
  USER_CLEAR_ACTIVE
}

export default userContansts;
