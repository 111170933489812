import React from 'react';
import PropTypes from 'prop-types';

class Icon extends React.Component{
  render(){
    return (
      <i className={this.props.icon} />
    );
  }
};

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
}

export default Icon;