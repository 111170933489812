import {
  TICKETS_FETCH,
  TICKETS_COUNT_FETCH,
} from '../constants';


const init = {
  tickets: [],
  total: 0
}

export function helpdesk(state = init, action) {
  switch (action.type) {
    case TICKETS_FETCH.SUCCESS:
      return {
        ...state,
        tickets: action.tickets,
      };
    case TICKETS_COUNT_FETCH.SUCCESS:
      return {
        ...state,
        total: action.total
      };
    default:
      return state;
  }
}
