import { createAsyncConstants } from '../../helpers/actions.js';

export const USERS_FETCH = createAsyncConstants('USERS_FETCH');
export const USERS_COUNT_FETCH = createAsyncConstants('USERS_COUNT_FETCH');

const usersContansts = {
  USERS_FETCH,
  USERS_COUNT_FETCH,
}

export default usersContansts;
