import { call, put, takeEvery } from 'redux-saga/effects';
import { createAction } from '../../helpers/actions';
import { serviceCall } from '../../helpers/effects';
import { CONTACT_REQUESTS_CREATE } from '../constants';
import { contactRequestsServices } from '../services';

import { alertActions } from './';

export const contactRequestsActions = {
  contactRequestsCreateRequest: (companyId, requestType, contactType, message) => createAction(CONTACT_REQUESTS_CREATE.REQUEST,
    { companyId: companyId, requestType: requestType, contactType: contactType, message: message }),
  contactRequestsCreateSuccess: (companyId, request) => createAction(CONTACT_REQUESTS_CREATE.SUCCESS,
    { companyId: companyId, request: request }),
  contactRequestsCreateFailure: (errorMessage) => createAction(CONTACT_REQUESTS_CREATE.FAILURE, { errorMessage: errorMessage }),
}

export function* createContactRequests(action) {
  try {
    const request = yield serviceCall(contactRequestsServices.createContactRequests, action.companyId, action.requestType, action.contactType, action.message);
    yield put(contactRequestsActions.contactRequestsCreateSuccess(action.companyId, request));
  } catch (e) {
    yield put(contactRequestsActions.contactRequestsCreateFailure(e.message));
    yield put(alertActions.error(`Failed to send: ${e.message}`, action.type));
  }
}

export const contactRequestsSagas = [
  takeEvery(CONTACT_REQUESTS_CREATE.REQUEST, createContactRequests),
]

