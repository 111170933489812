import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import _ from 'lodash';

// Material UI components
import { alpha } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';

// Drawer and side menu
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

// Bottom navigation menu
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';

import DashboardIcon from '@mui/icons-material/Dashboard';
import TimelineIcon from '@mui/icons-material/Timeline';
import PeopleIcon from '@mui/icons-material/People';
import DevicesIcon from '@mui/icons-material/Devices';

import Link from '@mui/material/Link';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    //marginTop: theme.spacing(3),
    [theme.breakpoints.up('lg')]: {
      margin: theme.spacing(1.5),
      boxShadow: "0 8px 10px -12px rgba(0,0,0,0.3)",
      flex: '1 1 100%',
      alignItems: 'stretch',
      padding: theme.spacing(2),
      fontSize: 'large',
    },
    [theme.breakpoints.down('xl')]: {
      position: 'fixed',
      bottom: '0px',
      left: '0px',
      right: '0px',
      zIndex: '100',
      borderColor: theme.palette.primary.dark,
      borderWidth: '1px 0 0 0',
      borderStyle: 'solid',
    },
  },
}));


const CompanyMenu = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const companyId = props.companyId || 0;
  const path = '/company/' + companyId;

  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(location.pathname);

  const onValueChange = () => {
    history.push(value);
  };
  useEffect(onValueChange, [value]);


  const onLocationChange = () => {
    setValue(location.pathname);
  };
  useEffect(onLocationChange, [location]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <BottomNavigation
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      showLabels
      className={classes.root}
    >
      <BottomNavigationAction
        label='Overview'
        value={path}
        icon={<DashboardIcon />}
      >
      </BottomNavigationAction>
      <BottomNavigationAction
        label='Network'
        value={path + '/network'}
        icon={<TimelineIcon />}
      >
      </BottomNavigationAction>
      <BottomNavigationAction
        label='Customers'
        value={path + '/customers'}
        icon={<PeopleIcon />}
      >
      </BottomNavigationAction>
      <BottomNavigationAction
        label='Equipment'
        value={path + '/equipment'}
        icon={<DevicesIcon />}
      >
      </BottomNavigationAction>
    </BottomNavigation>
  );
}


export default CompanyMenu;
