import { alertConstants } from '../constants';

export function alert(state = {}, action) {
  switch (action.type) {
    case alertConstants.SUCCESS:
      return {
        ...state,
        type: 'alert-success',
        message: action.message,
        action: action.action
      };
    case alertConstants.ERROR:
      return {
        ...state,
        type: 'alert-danger',
        message: action.message,
        action: action.action
      };
    case alertConstants.CLEAR:
      console.log("clearing alerts");
      return {};
    default:
      return state
  }
}
