import { call, put, race, delay, takeEvery } from 'redux-saga/effects';
import { createAction } from '../../helpers/actions'
import { alertConstants } from '../constants';

export const alertActions = {
  success: (message, action) => createAction(alertConstants.SUCCESS, { action: action, message: message }),
  error: (message, action) => createAction(alertConstants.ERROR, { action: action, message: message }),
  clear: () => createAction(alertConstants.CLEAR),
}

export const alertSagas = [
  takeEvery(alertConstants.SUCCESS, alertActions.success),
  takeEvery(alertConstants.ERROR, alertActions.error),
  takeEvery(alertConstants.CLEAR, alertActions.clear),
]
