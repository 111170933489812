import React, {useState, useEffect} from 'react';
import numeral from 'numeral';

import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';

import ListItemKeyValue from '../ListItemKeyValue';

const HotspotInfo = ({accessCode, currentSessions, bandwidth}) => {
  return (
    <Box>
      <List dense={true}>
        <ListItemKeyValue
          primary="Current Access Code"
          value={accessCode || 'N/A'}
        />
        <Divider component="li" />
        <ListItemKeyValue
          primary="Current Sessions"
          value={currentSessions || 'N/A'}
        />
        <Divider component="li" />
      </List>
    </Box>
  );
};

export default HotspotInfo;


/* Not Currently Used:

  const [upload, setUpload] = useState(0);
  const [download, setDownload] = useState(0);
  const [clients, setClients] = useState(0);
  const [days, setDays] = useState(0);

  const calcBandwidth = () => {
    let uploadTotal = 0;
    let downloadTotal = 0;
    let clientsTotal = 0;
    let daysTotal = 0;

    if (bandwidth) {
      //setBandwidth(bandwidth);

      console.log(bandwidth);
      bandwidth.map((day, i) => {
        uploadTotal += Number(day.totalOutgoing);
        downloadTotal += Number(day.totalIncoming);
        clientsTotal += Number(day.totalConnections);
        daysTotal += 1;

        return true;
      });
    }
    setUpload(uploadTotal);
    setDownload(downloadTotal);
    setClients(clientsTotal);
    setDays(daysTotal);
  };
  useEffect(calcBandwidth, [bandwidth]);



        <Divider component="li" />
        <ListItemKeyValue
          primary="Daily Downloaded Data"
          tooltip={'Average calculated from last 30 days of usage.'}
          value={numeral(download/days).format('0.00 ib')}
        />
        <Divider component="li" />
        <ListItemKeyValue
          primary="Daily Uploaded Data"
          tooltip={'Average calculated from last 30 days of usage.'}
          value={numeral(upload/days).format('0.00 ib')}
        />
        <Divider component="li" />
        <ListItemKeyValue
          primary="Daily Sessions"
          tooltip={'Average calculated from last 30 days of usage.'}
          value={days > 0 ? Number.parseInt(clients/days).toString() : 0}
        />

*/
