import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';

// Material-ui
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

import { Chart } from 'react-google-charts';

import Alert from '../../components/Alert';
import Button  from '../../components/Button';
import Divider from '../../components/Divider';
import { ErrorBoundry } from '../../components';

import { zabbixActions } from '../../redux/actions';

import {
  createErrorMessageSelector,
  createLoadingSelector
} from '../../helpers/selectors';

var webapi = require('../../helpers/webapi');

function _displayMB(stringBitCount){
  return (10000*(parseFloat(stringBitCount)/1000000) >> 0)/10000;
}

const HostBandwidth = (props) => {
  const host = props.host;
  const bandwidth = props.bandwidth;

  let rows = {
    bandwidth:
      bandwidth.up.map(up => [
        new Date(parseInt(up.clock) * 1000), //when
        null, // skip down
        _displayMB(up.value) // Value in MB
      ]).concat(
        bandwidth.down.map(down => [
          new Date(parseInt(down.clock) * 1000), // when
          _displayMB(down.value), // value in MB
          null // skip up
        ])
      )
  };

  // Add Headers
  var data = [['Date','down','up']].concat(rows.bandwidth);

  if (rows.bandwidth.length === 0) {
    return (
      <Box key={host + '-monitoring-ui'}>
        <h5>Gateway "{host}"</h5>
        <Alert type="secondary">- no bandwidth data available -</Alert>
      </Box>
    );
  }

  return (
    <Box key={host + '-monitoring-ui'}>
      <h5>Gateway "{host}"</h5>
      <Chart
        chartType="LineChart"
        title="Bandwidth Used Last 24 Hours"
        axisLabels={{
          h: 'Time',
          v: 'Mbps'
        }}
        data={data}
        options={{
          hAxis: { title: 'Time', },
          vAxis: { title: 'Mbps' },
          height: 400
        }}
      />
      <Divider />
    </Box>
  );
}

const ZabbixController = (props) => {
  const dispatch = useDispatch();

  const uid = props.uid || 0;

  const loading = {
    bandwidth: useSelector(createLoadingSelector(['BANDWIDTH_FETCH'])),
  }
  const errorMessage = {
    bandwidth: useSelector(createErrorMessageSelector(['BANDWIDTH_FETCH'])),
  }

  const hosts = useSelector(state => state?.zabbix?.data);

  const onFirstLoad = () => {
    dispatch(zabbixActions.bandwidthFetchRequest(uid));
  };
  useEffect(onFirstLoad, []);

  const handleRetry = () => {
    dispatch(zabbixActions.bandwidthFetchRequest(uid));
  }

  if (loading.bandwidth) {
    return (<LinearProgress />);
  }

  if (errorMessage.bandwidth) {
    return (
      <Box>
        <Alert type="warning">Failed to load data.</Alert>
        <Button text='Retry' onClick={handleRetry} />
      </Box>
    );
  }

  return (
    <Box>
      <ErrorBoundry errorMessage="unable to query reporting server">
        {hosts.length === 0 && <Alert type="secondary">- no gateway to monitor -</Alert> }
        {hosts.map( (host) => (
          <HostBandwidth
            key={host.host}
            host={host.host}
            bandwidth={host.bandwidth}
          />
        ))}
      </ErrorBoundry>
    </Box>
  );
};


export default ZabbixController;
