import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import makeStyles from '@mui/styles/makeStyles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';


import {
  createErrorMessageSelector,
  createLoadingSelector
} from '../../helpers/selectors';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    margin: '64px'
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export default function Home() {
  const classes = useStyles();
  const history = useHistory();

  const companies = useSelector(state => state.companies);

  const loading = useSelector(createLoadingSelector(['COMPANIES_FETCH']));
  const errorMessage = useSelector(createErrorMessageSelector(['COMPANIES_FETCH']));

  const onCompanies = () => {
    if (companies?.data[0]) {
      const companyId = companies.data[0].companyId;
      history.push('/company/' + companyId);
    }
  };
  useEffect(onCompanies, [companies]);

  if (loading) {
    return (<></>);
  }
  return (
    <Card className={classes.root} variant="outlined">
      <CardContent>
        <Typography variant="h5" component="h2">
          Welcome to the Ovation Networks Customer Dashboard
        </Typography>
        <Typography variant="body2" component="p">
          Please select a company page to view from the upper right.
        </Typography>
      </CardContent>
    </Card>
  );
}
