import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import thunkMiddleware from 'redux-thunk';
// import { createLogger } from 'redux-logger';

import reducers from '../redux/reducers';
import { Sagas } from '../redux/actions';

// TODO: Add history?
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
  console.log("Found redux dev tools");
} else {
  console.log("no dev tools");
}

// const loggerMiddleware = createLogger();
const sagaMiddleware = createSagaMiddleware()

export const store = createStore(
  reducers,
  composeEnhancers(
    applyMiddleware(
      sagaMiddleware,
      thunkMiddleware
      //loggerMiddleware
    ),
  )
)

sagaMiddleware.run(Sagas);

