import { feathersClient as feathers } from '../../helpers/service';

const fetchCompany = async (companyId) => {
  companyId = companyId ? companyId : 0;
  const company = await feathers.service('companies').get(companyId);

  const info = company.accountManager?.contactInfo ?? {};
  company.am = {
    name: `${info.contactFirstName} ${info.contactLastName}`,
    email: info.contactEmail,
    phone: `(319) 365-6200 ext 6${company.accountManager.userId}`
  };
  company.cityName = company.cityname?.data[0]?.companyCityName ?? '';

  return company
};

const updateEmailList = async (companyId, emailList) => {
  console.log("Updating email list", emailList);

  companyId = companyId ? companyId : 0;

  if ( !Array.isArray(emailList) ) {
    throw Error("Email list must be an array");
  }

  let emailListStr = emailList.join(', ');

  const company = await feathers.service('companies').patch(
    companyId, { companyRadEmail: emailListStr }
  );

  console.log("Company returned: ", company);

  return company
};

export const companyServices = {
  fetchCompany,
  updateEmailList,
};

