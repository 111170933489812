import { call, put, race, delay, takeEvery } from 'redux-saga/effects';
import { createAction } from '../../helpers/actions'
import { serviceCall } from '../../helpers/effects';

import {
  HOTSPOT_USAGE_FETCH,
} from '../constants';
import { hotspotServices } from '../services';

import { alertActions } from './';


export const hotspotActions = {
  hotspotUsageFetchRequest: (companyId) => createAction(HOTSPOT_USAGE_FETCH.REQUEST, { companyId: companyId }),
  hotspotUsageFetchSuccess: (usage) => createAction(HOTSPOT_USAGE_FETCH.SUCCESS, { usage: usage }),
  hotspotUsageFetchFailure: (errorMessage) => createAction(HOTSPOT_USAGE_FETCH.FAILURE, { errorMessage: errorMessage }),
}

export function* fetchHotspotUsage(action) {
  try {
    const usage = yield serviceCall(hotspotServices.fetchHotspotUsage, action.companyId);
    yield put(hotspotActions.hotspotUsageFetchSuccess(usage));
  } catch (e) {
    yield put(hotspotActions.hotspotUsageFetchFailure(e.message));
  }
}

export const hotspotSagas = [
  takeEvery(HOTSPOT_USAGE_FETCH.REQUEST, fetchHotspotUsage)
]
