import { createAsyncConstants } from '../../helpers/actions.js';

export const COMPANY_FETCH = createAsyncConstants('COMPANY_FETCH');
export const COMPANY_UPDATE_EMAIL_LIST = createAsyncConstants('COMPANY_UPDATE_EMAIL_LIST');
export const COMPANY_CLEAR_ACTIVE = 'COMPANY_CLEAR_ACTIVE';

const companyContansts = {
  COMPANY_FETCH,
  COMPANY_UPDATE_EMAIL_LIST,
  COMPANY_CLEAR_ACTIVE,
}

export default companyContansts;
