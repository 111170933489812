import {
  COMPANIES_FETCH,
} from '../constants/companies.constants';

const init = {
  data: []
}
export const companies = (state = init, action) => {
  switch (action.type) {
    case COMPANIES_FETCH.SUCCESS:
      return {
        ...state,
        data: action.companies
      };
    default:
      return state
  }
};
