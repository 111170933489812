import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { alpha } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';

const useStyles = makeStyles((theme) => ({
  footerWrapper: {
    marginTop: '10px',
    padding: '10px'
  },
  footer: {
  }
}));


function Footer(props) {
  const classes = useStyles();
  const [loggedIn] = useState(props.loggedIn);

  return (
    <Container className={classes.footerWrapper}>
      <div className={classes.footer}>
      </div>
    </Container>
  );
}

function mapStateToProps(state) {
  const { authentication } = state;
  return (authentication);
}

export default connect(mapStateToProps)(Footer);
