
const getAPIAddress = () => {
  const port = window.location.hostname === 'localhost' ? ':3030' : '';
  const path = window.location.hostname === 'localhost' ? '' : '/api';
  return `//${window.location.hostname}${port}${path}`;
}

const getWebSocketAddress = () => {
  const proto = window.location.protocol === 'https:' ? 'wss' : 'ws';
  const port = window.location.hostname === 'localhost' ? ':3030' : '';
  return `${proto}://${window.location.hostname}${port}/`;
};

const config = {
 api: {
   address: getAPIAddress()
   //address: 'http://dashboard.ovationnetworks.com/api/'
 },
 ws: {
   address: getWebSocketAddress()
   //address: 'ws://dashboard.ovationnetworks.com'
 }
}

export default config
