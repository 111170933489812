import React from 'react';

import Box from '@mui/material/Box';
import FormControl from  '@mui/material/FormControl'; import Typography from '@mui/material/Typography';


const AddressBlock = ({
  displayName = '',
  address1 = '',
  address2 = '',
  cityname = '',
  statename = '',
  zip = ''
}) => {
  return (
    <Box>
      { displayName.length > 0 &&
        <FormControl className={'fullWidth companyName'} fullWidth={true}>
          <Typography variant="h6">{displayName}</Typography>
        </FormControl>
      }
      { address1.length > 0 &&
        <FormControl className={'fullWidth'} fullWidth={true}>
          <Typography variant="body2">{address1}</Typography>
        </FormControl>
      }
      { address2.length > 0 &&
      <FormControl className={'fullWidth'} fullWidth={true}>
        <Typography variant="body2">{address2}</Typography>
      </FormControl>
      }
      <FormControl size="medium">
        <Typography variant="body2">{cityname}, {statename} {zip}</Typography>
      </FormControl>
      <br />
    </Box>
  );
};

export default AddressBlock;
