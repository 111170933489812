import React, { useState, useEffect } from 'react';
import { styled, alpha } from '@mui/material/styles';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

// Material-UI
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import InputBase from '@mui/material/InputBase';

// Material-UI Icons
import AccountCircle from '@mui/icons-material/AccountCircle';
import SearchIcon from '@mui/icons-material/Search';

// Components
import ErrorBoundry from '../../components/ErrorBoundry';

// HOCs
import CompanySearch from '../CompanySearch';

// Actions
import { authenticationActions } from '../../redux/actions';

// Helpers
import { isAdmin, isOwner, isManager } from '../../helpers';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}))


function OvatnAppBar(props) {
  const dispatch = useDispatch();

  const history = useHistory();

  const { loggedIn } = useSelector(state => state.authentication);
  const { user } = useSelector(state => state.authentication);

  const [anchorEl, setAnchorEl] = useState(null);
  const [role] = useState(props.role);
  const [location, setLocation] = useState(props.location);

  const isMenuOpen = Boolean(anchorEl);

  const [showManagerControls, setManagerControls] = useState(false);

  useEffect(() => {
    if (loggedIn) {
      setLocation(props.location);
    } else {
      // history.push('/login');
    }
  }, [loggedIn]);

  useEffect(() => {
    if (user?.roles) {
      // TODO: Currently only Admins are allowed access to manage users
      //setManagerControls(isAdmin(user.roles) || isOwner(user.roles) || isManager(user.roles));
      setManagerControls(isAdmin(user.roles));
    }
  }, [user]);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  }

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const thenClose = (f) => () => { const r = f(); handleMenuClose(); return r; };

  const menuId = 'primary-search-account-menu';
  const managerLinks = [{
    'name': 'users',
    'label': 'Users',
    'action': () => history.push('/users'),
  }];
  const userLinks = [{
    'name': 'logout',
    'label': 'Logout',
    'action': () => dispatch(authenticationActions.logoutRequest()),
  }]

  //anchorOrigin={{ vertical: 'top', horizontal: 'right', marginTop: '10px' }}
  //transformOrigin={{ vertical: 'top', horizontal: 'right', marginTop: '10px' }}
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      id={menuId}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      { // if user ia a manager show manager links
        showManagerControls && managerLinks.map(link => (
          <MenuItem key={link.name} onClick={thenClose(link.action)}>
            <Typography textAlign="center">{link.label}</Typography>
          </MenuItem>
        ))
      }
      { // show links for all users
        userLinks.map(link => (
          <MenuItem key={link.name} onClick={thenClose(link.action)}>
            <Typography textAlign="center">{link.label}</Typography>
          </MenuItem>
        ))
      }
    </Menu>
  );
  const AppControls = (
    <>
      <Search>
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <CompanySearch label='select company' />
      </Search>
      <Box>
        <IconButton
          edge="end"
          aria-label="account of current user"
          aria-controls={menuId}
          aria-haspopup="true"
          onClick={handleMenuOpen}
          color="inherit"
          size="large">
          <AccountCircle />
        </IconButton>
      </Box>
    </>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <Link
            href={'/'}
            variant="body2"
            underline="none"
            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
          >
            <img src={'/images/ovn_dashboard_appbar.png'} />
          </Link>
          { loggedIn && AppControls }
        </Toolbar>
      </AppBar>
      {renderMenu}
    </Box>
  );
}

export default OvatnAppBar;
