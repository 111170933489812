import React, { useState, useEffect } from 'react';

import { styled } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';

// Not used
import InputLabel from '@mui/material/InputLabel';

const NoDisabledAutocomplete = styled(Autocomplete)( ({theme}) => ({
  "& .Mui-disabled": {
    //color: "rgba(0, 0, 0, 0.8)", // (default alpha is 0.38)
    opacity: 1,
  }
}));

const NoDisabledChip = styled(Chip)( ({theme}) => ({
  "&.Mui-disabled": {
    //color: "rgba(0, 0, 0, 0.8)", // (default alpha is 0.38)
    opacity: 1,
  }
}));

const InputChip = ({ label = '', disabled = false, values = [], onChange = null }) => {
  let disabledProps = {};
  if (disabled) {
    disabledProps = {
      onDelete: null
    };
  }
  return (
    <Box sx={{width: '100%'}}>
      <NoDisabledAutocomplete
          disabled={disabled}
          multiple
          id="tags-filled"
          options={[]}
          freeSolo
          disableClearable
          value={values}
          renderTags={(value, getTagProps) =>
            value.map((option, index) =>
              <NoDisabledChip
                variant="outlined"
                label={option}
                {...getTagProps({ index })}
                {...disabledProps}
              />
            )
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label={label}
              placeholder={disabled ? '' : "Add Email"}
            />
          )}
          onChange={onChange}
        />
    </Box>
  );
};

export default InputChip;
