import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams, Redirect } from 'react-router-dom';

import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';

import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';

import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

import PersonAddIcon from '@mui/icons-material/PersonAdd';
import DeleteIcon from '@mui/icons-material/Delete';
import LockIcon from '@mui/icons-material/Lock';
import SecurityIcon from '@mui/icons-material/Security';
import Email from '@mui/icons-material/Email';

import {
  createErrorMessageSelector,
  createLoadingSelector
} from '../../helpers/selectors';

import LinearProgress from '@mui/material/LinearProgress';

// Components
import ErrorBoundry from '../../components/ErrorBoundry';
import Section from '../../components/Section';

// HOC
import UserRoles from '../../hoc/UserRoles';

import {
  userActions,
  authenticationActions
} from '../../redux/actions';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(2),
  },
}));

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  title: {
    flex: '1 1 100%',
  },
}));


const UserToolbar = ({user}) => {
  const dispatch = useDispatch();
  const classes = useToolbarStyles();
  const history = useHistory();

  const handleDeleteUserClick = (e) => {
    dispatch(userActions.userRemoveRequest(user.id));
    history.push('/users');
  };

  const handlePasswordResetClick = (e) => {
    dispatch(authenticationActions.sendResetEmailRequest(user.email));
  }

  const handleVerifyEmailClick = (e) => {
    dispatch(authenticationActions.resendVerifySignupRequest(user.email));
  }

  return (
    <Toolbar className={classes.root}>
      <Typography
        className={classes.title}
        variant="h6"
        id="tableTitle"
        component="div"
      >
      </Typography>
      <Tooltip title="Resend Email Verification">
        <IconButton
          aria-label="person list"
          disabled={user.isVerified === 1}
          onClick={handleVerifyEmailClick}
          size="large">
          <Email />
        </IconButton>
      </Tooltip>
      <Tooltip title="Send Reset Password">
        <IconButton aria-label="person list" onClick={handlePasswordResetClick} size="large">
          <SecurityIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Disable User">
        <IconButton aria-label="person list" onClick={handleDeleteUserClick} size="large">
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    </Toolbar>
  );
};

function User(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const params = useParams();

  const user = useSelector(state => state?.user?.current);

  const loading = {
    user: useSelector(createLoadingSelector(['USER_FETCH'])),
  }
  const errorMessage = {
    user: useSelector(createErrorMessageSelector(['USER_FETCH'])),
  }

  // Local component state
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentUser, setCurrentUser] = useState({
    firstName: '',
    lastName: '',
    displayName: '',
    email: '',
  });
  const [hasChanges, setHasChanges] = useState(false);

  // On first load, only runs once
  const onFirstLoad = () => {
    if (params.userId) {
      dispatch(userActions.userFetchRequest(params.userId));
    }
    return () => dispatch(userActions.userClearActive());
  };
  useEffect(onFirstLoad, [])

  // TODO: Fix this, it's causing a loop redirect through an undefined userId
  // and then to the real userId
  const onCurrentUser = () => {
    if (user && user.id) {
      if (!loading.user && params.userId !== user.id) {
        history.push('/user/'+ user.id);
      }
      console.log("User:", user);
      setCurrentUser(user);
      setHasChanges(false);
    }
  };
  useEffect(onCurrentUser, [user]);

  const handleChange = (e) => {
    setCurrentUser({...currentUser, [e.target.name]: e.target.value});
    setHasChanges(true);
  };

  const handleSaveUser = (e) => {
    if (params.userId) {
      dispatch(userActions.userUpdateRequest(currentUser));
    } else {
      dispatch(userActions.userCreateRequest(currentUser));
    }
  };

  if ( errorMessage.user ) {
    return (
      <Redirect to={{ pathname: '/users', state: { from: props.location } }} />
    )
  }

  return (
    <Container maxWidth="lg">
        <ErrorBoundry errorMessage={errorMessage.users}>
          <Grid container spacing={3}>
            <Section title={params.userId ? 'Manage User' : 'New User'}
              xs={12}
              toolbar={<UserToolbar user={currentUser}/>}
            >
              <FormControl component="div" margin="normal" fullWidth={true}>
                <TextField
                  id="First Name"
                  name="firstName"
                  type='text'
                  label="First Name"
                  variant="standard"
                  disabled={loading.user ? true : false}
                  value={currentUser.firstName}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl component="div" margin="normal" fullWidth={true}>
                <TextField
                  id="Last Name"
                  name="lastName"
                  type='text'
                  label="Last Name"
                  variant="standard"
                  disabled={loading.user ? true : false}
                  value={currentUser.lastName}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl component="div" margin="normal" fullWidth={true}>
                <TextField
                  id="Display Name"
                  name="displayName"
                  type='text'
                  label="Display Name"
                  variant="standard"
                  disabled={loading.user ? true : false}
                  value={currentUser.displayName}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl component="div" margin="normal" fullWidth={true}>
                <TextField
                  id="Email"
                  name="email"
                  type='text'
                  label="Email Address"
                  variant="standard"
                  disabled={loading.user ? true : false}
                  value={currentUser.email}
                  onChange={handleChange}
                />
              </FormControl>
              <Grid container justifyContent="flex-end" spacing={3}>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    disabled={!hasChanges}
                    className={classes.button}
                    startIcon={<SaveIcon />}
                    onClick={handleSaveUser}
                  >
                    {params.userId ? 'Save' : 'Add'}
                  </Button>
                </Grid>
              </Grid>
            </Section>
            { params.userId &&
              <Section title={''} xs={12}>
                <UserRoles roles={user?.roles} userId={params.userId} />
              </Section>
            }
          </Grid>
        </ErrorBoundry>
    </Container>
  );
}

export default User
