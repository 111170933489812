import { call, put, race, delay, takeEvery } from 'redux-saga/effects';
import { createAction } from '../../helpers/actions'
import { serviceCall } from '../../helpers/effects';

import {
  COMPANY_FETCH,
  COMPANY_UPDATE_EMAIL_LIST,
  COMPANY_CLEAR_ACTIVE
} from '../constants';

import { companyServices } from '../services';

import { alertActions } from './';

export const companyActions = {
  companyFetchRequest: (companyId) => createAction(COMPANY_FETCH.REQUEST, { companyId: companyId }),
  companyFetchSuccess: (company) => createAction(COMPANY_FETCH.SUCCESS, { company: company }),
  companyFetchFailure: (errorMessage) => createAction(COMPANY_FETCH.FAILURE, { errorMessage: errorMessage }),

  companyUpdateEmailListRequest: (companyId, emailList) => createAction(COMPANY_UPDATE_EMAIL_LIST.REQUEST, { companyId: companyId, emailList: emailList }),
  companyUpdateEmailListSuccess: (company) => createAction(COMPANY_UPDATE_EMAIL_LIST.SUCCESS, { company: company }),
  companyUpdateEmailListFailure: (errorMessage) => createAction(COMPANY_UPDATE_EMAIL_LIST.FAILURE, { errorMessage: errorMessage }),

  companyClearActive: () => createAction(COMPANY_CLEAR_ACTIVE, {}),
}

export function* fetchCompany(action) {
  try {
    const company = yield serviceCall(companyServices.fetchCompany, action.companyId);
    yield put(companyActions.companyFetchSuccess(company));
  } catch (e) {
    yield put(companyActions.companyFetchFailure(e.message));
    yield put(alertActions.error(`Failed to get company: ${e.message}`, action.type));
  }
}

export function* patchEmailList(action) {
  try {
    const company = yield serviceCall(companyServices.updateEmailList, action.companyId, action.emailList);
    yield put(companyActions.companyUpdateEmailListSuccess(company));
  } catch (e) {
    yield put(companyActions.companyUpdateEmailListFailure(e.message));
    console.log(action);
    yield put(alertActions.error(`Failed to update email list: ${e.message}`, action.type));
  }
}


export const companySagas = [
  takeEvery(COMPANY_FETCH.REQUEST, fetchCompany),
  takeEvery(COMPANY_UPDATE_EMAIL_LIST.REQUEST, patchEmailList)
]

