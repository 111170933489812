import { call, put, takeEvery } from 'redux-saga/effects';
import { createAction } from '../../helpers/actions';
import { serviceCall } from '../../helpers/effects';
import { COMPANIES_FETCH } from '../constants';
import { companiesServices } from '../services';

import { alertActions } from './';

export const companiesActions = {
  companiesFetchRequest: () => createAction(COMPANIES_FETCH.REQUEST),
  companiesFetchSuccess: (companies) => createAction(COMPANIES_FETCH.SUCCESS, { companies: companies }),
  companiesFetchFailure: (errorMessage) => createAction(COMPANIES_FETCH.FAILURE, { errorMessage: errorMessage }),
}

export function* fetchCompanies(action) {
  try {
    const companies = yield serviceCall(companiesServices.fetchCompanies);
    yield put(companiesActions.companiesFetchSuccess(companies));
  } catch (e) {
    yield put(companiesActions.companiesFetchFailure(e.message));
    yield put(alertActions.error(`Failed to get companies: ${e.message}`, action.type));
  }
}

export const companiesSagas = [
  takeEvery(COMPANIES_FETCH.REQUEST, fetchCompanies),
]

