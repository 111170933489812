// password rotation webapi
module.exports = {
  setCompanyPasswordNotifyEmailList: function(uid, emails){
   return fetch({
     url: '/api/companies/' + uid + '/password-rotation-emails',
     method: 'PUT',
     data: {
       emailList: emails
     },
     dataType: 'json'
   });
 },

 companyTriggerPasswordRotation: function(uid){
   return fetch({
     url: '/api/companies/' + uid + '/trigger-password-rotation',
     method: 'PUT',
     dataType: 'json'
   });
 }
};
