import { feathersClient as feathers } from '../../helpers/service';

const fetchHotspotUsage = async (companyId) => {
  companyId = companyId ? companyId : 0;

  const query = {
    query: {
      uid: companyId,
      ip: {
        $ne: null
      },
      updatedAt: {
        $gt: new Date(new Date() - 1000 * 60 * 60),
      },
      //$summary: true,
      $limit: 0
    }
  };

  const usage = await feathers.service('hotspot-usage').find(query);

  return { current: usage.total, data: [] };

  //return usage;
}

export const hotspotServices = {
  fetchHotspotUsage,
};
