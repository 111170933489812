import { feathersClient as feathers } from '../../helpers/service';

const fetchUser = async (userId) => {
  userId = userId ? userId : 0;
  const user = await feathers.service('users').get(userId);

  return user
}

const updateUser = async (user) => {
  //userId = userId ? userId : 0;
  user = await feathers.service('users').patch(user.id, {
    firstName: user.firstName,
    lastName: user.lastName,
    displayName: user.displayName,
    //email: user.email
  });

  return user
}


const createUser = async (user) => {
  //userId = userId ? userId : 0;
  user = await feathers.service('users').create({
    firstName: user.firstName,
    lastName: user.lastName,
    displayName: user.displayName,
    email: user.email
  });

  return user
}

const removeUser = async (userId) => {
  const user = await feathers.service('users').remove(userId);

  return user;
}

const fetchUserRoles = async (userId, options) => {
  const defaults = {
    search: null,
    filter: {},
    sort: 'id',
    order: 0,
    page: 1,
    size: 10
  };
  const { search, filter, sort, order, page, size } = Object.assign({}, defaults, options);

  userId = userId ? userId : 0;
  const skip = page * size;

  const query = {
    query: {
      userId: userId,
      $sort: { [sort]: order },
      $limit: size,
      $skip: skip
    }
  };
  const roles = await feathers.service('user-roles').find(query);

  return roles.data;
}

const fetchUserRolesCount = async (userId) => {
  userId = userId ? userId : 0;
  const query = {
    query: {
      userId: userId,
      $limit: 0
    }
  };
  const roles = await feathers.service('user-roles').find(query);
  return roles.total;
};

const addUserRoles = async (role) => {
  role = await feathers.service('user-roles').create(role);

  return role;
}

const removeUserRoles = async (roleId) => {
  const role = await feathers.service('user-roles').remove(roleId);

  return role;
}

export const userServices = {
  fetchUser,
  updateUser,
  createUser,
  removeUser,
  fetchUserRoles,
  fetchUserRolesCount,
  addUserRoles,
  removeUserRoles,
};

