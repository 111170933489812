import React from 'react';

import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';

import List from '@mui/material/List';
import Divider from '@mui/material/Divider';

import ListItemKeyValue from '../ListItemKeyValue';

const useStyles = makeStyles((theme) => ({
  root: {},
  active: {
    alignItems: 'center',
    color: theme.palette.secondary.main,
  },
  inactive: {
    alignItems: 'center',
    color: theme.palette.error.main
  },
  expired: {
    alignItems: 'center',
    color: theme.palette.error.main
  }
}));


const AccountInfo = ({ accountStatus, roomCount, contractStart, hotspotStatus }) => {
  const classes = useStyles();

  const contractStatus = (endDate) => {
    let checkDate = Date.parse(endDate);

    let status = {
      style: classes.unknown,
      value: 'Unknown'
    };

    if (checkDate > Date.now()) {
      status = { style: classes.active, value: 'Active' };
    }
    if (checkDate < Date.now()) {
      status = { style: classes.expired, value: 'Expired' };
    }
    return status;
  };

  const creditStatus = (credit=0) => {
    let status = {
      style: classes.unknown,
      value: 'Unknown'
    };

    switch(credit){
      case 0:
        status = { style: classes.active, value: 'Status is GOOD!' }
        break;
      case 1:
        status = { style: classes.active, value: 'Delinquent 30 Days' }
        break;
      case 2:
        status = { style: classes.active, value: 'Delinquent 60 Days' }
        break;
      case 3:
        status = { style: classes.active, value: 'Delinquent 90 Days' }
        break;
      case 99:
        status = { style: classes.active, value: 'Suspended'}
        break;
    }
    return status;
  };

  return (
    <Box>
      <List dense={true}>
        <ListItemKeyValue
          primary="Account Status"
          value={creditStatus(accountStatus).value}
          valueClassName={creditStatus(accountStatus).style}
        />
        <Divider component="li" />
        <ListItemKeyValue
          primary="Number of Rooms"
          value={roomCount}
        />
        <Divider component="li" />
        <ListItemKeyValue
          primary="Contract Start"
          tooltip="Auto renews anually"
          value={contractStart}
          valueClassName={contractStart}
        />
      </List>
    </Box>
  )
};

export default AccountInfo;
