import React, { useState } from "react";

import { styled } from '@mui/material/styles';

import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Grid';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';

import PrintIcon from '@mui/icons-material/Print';
import CloseIcon from '@mui/icons-material/Close';

import RenderInWindow from '../../components/RenderInWindow'
import AccessCodeCutsheet from './AccessCodeCutsheet';


const NoPrint = styled('div')( ({theme}) => ({
  '@media print': {
    display: 'none',
  }
}));
const OnlyPrint = styled('div')( ({theme}) => ({
  '@media print': {
    marginTop: '64px',
    '@page ': {
      size: 'A3',
    },
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const AccessCodeLink = ({companyId = 0, label = "open", accessCode}) => {
  const [ open, setOpen ] = useState(false);
  const [ documentTitle, setDocumentTitle ] = useState();

  const handleClose = () => {
    document.title = documentTitle;
    setOpen(false);
  }
  const handleOpen = () => {
    setDocumentTitle(document.title);
    document.title = 'AccessCode_Cutsheet';
    setOpen(true);
  }

  const handlePrint = () => window.print();

  return (
    <>
      <Button sx={{ marginTop: '0px', marginBottom: '0px', minHeight: 0, }} onClick={handleOpen}>{label}</Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
      >
        <NoPrint>
          <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handlePrint}
                aria-label="print"
              >
                <PrintIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Access Codes
              </Typography>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
        </NoPrint>
        <OnlyPrint>
          <Grid container spacing={1} columns={{ xs: 4, sm: 8, md: 12 }}>
            <Grid item xs={2} sm={4} md={4}>
              <AccessCodeCutsheet accessCode={accessCode} companyId={companyId} />
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <AccessCodeCutsheet accessCode={accessCode} companyId={companyId} />
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <AccessCodeCutsheet accessCode={accessCode} companyId={companyId} />
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <AccessCodeCutsheet accessCode={accessCode} companyId={companyId} />
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <AccessCodeCutsheet accessCode={accessCode} companyId={companyId} />
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <AccessCodeCutsheet accessCode={accessCode} companyId={companyId} />
            </Grid>
          </Grid>
        </OnlyPrint>
      </Dialog>
    </>
  );
};

export default AccessCodeLink; 
