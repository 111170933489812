import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { compose } from 'redux';
import { Redirect, useParams } from 'react-router-dom';

import { alpha } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';

import {
  createErrorMessageSelector,
  createLoadingSelector
} from '../../helpers/selectors';

import LinearProgress from '@mui/material/LinearProgress';

// Components
import ErrorBoundry from '../../components/ErrorBoundry';
import Section from '../../components/Section';
import CompanyMenu from  '../../components/CompanyMenu';
import CompanyInfo from '../../components/CompanyInfo';
import CompanyMap from '../../components/CompanyMap';
import Billing from '../../components/Billing';
import AMInfo from '../../components/AMInfo';
import AccountInfo from '../../components/AccountInfo';
import HotspotInfo from '../../components/HotspotInfo';
import AccessCodeDetail from '../../components/AccessCodeDetail';
import ZabbixController from '../../hoc/ZabbixController';
import TicketList from '../../components/ticketList';
import {
  companyActions,
  helpdeskActions,
  hotspotActions } from '../../redux/actions';
// Helpers
import { store, isIHG, isAdmin, isOwner } from '../../helpers'

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
    marginBottom: '100px',
  },
}));

function Company(props) {
  const dispatch = useDispatch();
  const classes = useStyles();

  // Pull Router Params
  const { companyId } = useParams();

  // Component State
  const [ am, setAm ] = useState({})
  const [ emailList, setEmailList ] = useState([]);

  // Redux Store
  const { user } = useSelector(state => state.authentication);
  const { helpdesk, hotspot } = useSelector(state => state);
  const company = useSelector(state => state.company);

  const loading = {
    company: useSelector(createLoadingSelector(['COMPANY_FETCH'])),
    //tickets: useSelector(createLoadingSelector(['TICKETS_FETCH'])),
    hotspot: useSelector(createLoadingSelector(['HOTSPOT_USAGE_FETCH'])),
  }
  const errorMessage = {
    company: useSelector(createErrorMessageSelector(['COMPANY_FETCH'])),
    //tickets: useSelector(createErrorMessageSelector(['TICKETS_FETCH'])),
    hotspot: useSelector(createErrorMessageSelector(['HOTSPOT_USAGE_FETCH'])),
  }

  // Effects
  const onCompanyUpdate = () => {
    if ( company?.companyRadEmail ) {
      setEmailList(company?.companyRadEmail?.split(',').map(s => s.trim()));
      return;
    }
    setEmailList([]);
    //return () => dispatch(companyActions.companyClearActive());
  };
  useEffect(onCompanyUpdate, [company]);

  const companyIdUpdate = () => {
    if (companyId) {
      dispatch(companyActions.companyFetchRequest(companyId));
      //dispatch(helpdeskActions.ticketsFetchRequest(companyId));
      dispatch(hotspotActions.hotspotUsageFetchRequest(companyId));
    }
  };
  useEffect(companyIdUpdate, [companyId]);


  const handleEmailListChange = (e, emailList) => {
    console.log("company view updating email list");
    dispatch(companyActions.companyUpdateEmailListRequest(companyId, emailList));
  }


  if ( errorMessage.company ) {
    return (
      <Redirect to={{ pathname: '/home', state: { from: props.location } }} />
    )
  }

  //<CircularProgress className={classes.loading} />
  if ( !company || loading.company ) {
    return (
      <Container maxWidth="lg">
        <div className={classes.root}>
          <Grid container direction="column" justifyContent="center" alignItems="center" alignContent="center">
            <Grid item>
              <LinearProgress />
            </Grid>
          </Grid>
        </div>
      </Container>
    );
  }
  //variant="permanent"

  const renderSections = () => {
    if (!isIHG(company.tags)) {
      return <>
        <Section title={"Hotspot Information"} xs={12} md={6}>
          <HotspotInfo
            accessCode = {company.companyRadPassword}
            currentSessions = {hotspot?.usage?.current}
            usage = {(hotspot || {}).usage}
            isLoading = {loading.hotspot}
          />
        </Section>
        <Section title={"Access Code"} xs={12} md={12}>
          <AccessCodeDetail
            companyId={companyId}
            editable={isAdmin(user.roles)}
            accessCode={company.companyRadPassword}
            accessCodeType={company.companyRadType}
            emailList={emailList}
            handleChange={handleEmailListChange}
          />
        </Section>
        <Section title={"Monitoring"} xs={12}>
          <ZabbixController uid={companyId} />
        </Section>
      </>
    }
  }

  return (
    <Container maxWidth="lg">
      <div className={classes.root}>
        <ErrorBoundry errorMessage={errorMessage.company}>
          <Grid container spacing={3}>
            <Section title={"Company Info"} xs={12}>
              <CompanyInfo
                companyId={companyId}
                displayName={company.companyPropname}
                address1={company.companyAddress1}
                address2={company.companyAddress2}
                cityname={company.cityName}
                statename={company.companyStatenew}
                zip={company.companyZip}
                phone1={company.companyPhone1}
                phone2={company.companyPhone2}
                fax={company.companyFax}
              />
            </Section>
            {/*
            <CompanyMenu companyId={companyId} />
            */}
            <Section title={"Account Manager"} xs={12} md={6}>
              <AMInfo
                companyId={companyId}
                accountManager={company.am?.name}
                contactEmail={company.am?.email}
                contactPhone={company.am?.phone}
              />
            </Section>
            <Section title={"Billing"} xs={12} md={6}>
              <Billing
                token={company?.token}
              />
            </Section>
            <Section title={"Account Information"} xs>
              <AccountInfo
                accountStatus={company.companyCredit}
                roomCount={company.roomsTotal}
                contractStart={company.companyStart}
                hotspotStatus={''}
              />
            </Section>
            { renderSections() }

            {/*
            <Grid item xs={12}>
              <Typography variant="h5" color="secondary">Gateway Interface</Typography>
              <Paper className={'CompanySection'}></Paper>
            </Grid>
            */}
            <Section title={"Guests"} xs={12}>
              <TicketList companyId={companyId} />
            </Section>
          </Grid>
        </ErrorBoundry>
      </div>
    </Container>
  )
}

export default Company
