import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { compose } from 'redux';
import { useHistory, Redirect, useParams } from 'react-router-dom';

import { alpha } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import {
  createErrorMessageSelector,
  createLoadingSelector
} from '../../helpers/selectors';

import LinearProgress from '@mui/material/LinearProgress';

// Components
import Section from '../../components/Section';
import ErrorBoundry from '../../components/ErrorBoundry';

import UserList from '../../hoc/UserList';

import {
  authenticationActions,
  alertActions
} from '../../redux/actions';
import { store } from '../../helpers'


const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(2),
  },
}));


const HidePasswordAdornment = (props) => (
  <InputAdornment position="end">
    <IconButton
      aria-label="toggle password visibility"
      {...props}
      edge="end"
      size="large">
      {props.showPassword ? <Visibility /> : <VisibilityOff />}
    </IconButton>
  </InputAdornment>
);



const ResetPassword = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();

  const loading = useSelector(createLoadingSelector(['AUTHENTICATION_RESET_PASSWORD_TOKEN']));
  const errorMessage = useSelector(createErrorMessageSelector(['AUTHENTICATION_RESET_PASSWORD_TOKEN']));

  const { token } = useParams();

  const [ password, setPassword ] = useState('');
  const [ confirm, setConfirm ] = useState('');
  const [ submitted, setSubmitted ] = useState(false);
  const [ showPassword, setShowPassword ] = useState(false);

  const onError = () => {
    dispatch(alertActions.error(errorMessage));
    setSubmitted(false);
  };
  useEffect(onError, [errorMessage]);

  const handlePasswordChange = (e) => setPassword(e.target.value);
  const handleConfirmChange = (e) => setConfirm(e.target.value);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(token);
    let valid = password.length > 0;
    let match = password === confirm;
    if (valid && match) {
      dispatch(authenticationActions.resetPasswordWithTokenRequest(token, password));
      setSubmitted(true);
    } else {
      // Maybe change this to show error on the fields
      dispatch(alertActions.error("Passwords are empty or do not match"));
    }
  };

  const handleShowPassword = (e) => {
    setShowPassword(!showPassword);
  };

  const handleGetStartedClick = (e) => {
    history.push('/login');
  };

  if (loading === false && submitted === true && errorMessage === '') {
    return (
      <Container maxWidth="lg">
        <Paper className={classes.root}>
          <Grid container spacing={3} direction="column" justifyContent="center" alignItems="center">
            <Grid item>
              <Typography variant="h2" component="h2">
                Thank you!
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h5" component="p">
                Your password has now been reset.
              </Typography>
            </Grid>
            <Grid item>
              <div className={'UserActionButtons'}>
                <FormControl component="div" margin="normal">
                  <Button
                    variant="contained"
                    color="primary"
                    size='medium'
                    onClick={handleGetStartedClick}
                  >Get Started</Button>
                </FormControl>
              </div>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    );
  }


  return (
    <Container maxWidth="lg">
      <Paper className={classes.root}>
        <Grid container spacing={3} direction="column" justifyContent="center" alignItems="center">

          <Grid item>
            <Typography variant="h2" component="h2">
              Time for Change
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h5" component="p">
              Please reset your account password below.
            </Typography>
          </Grid>

          <Grid item>
            <form name="resetPassword" onSubmit={handleSubmit}>
              <FormControl component="div" margin="normal" fullWidth>
                <TextField
                  id="password"
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  label="password"
                  autoComplete="new-password"
                  variant="outlined"
                  fullWidth
                  disabled={loading ? true : false}
                  onChange={handlePasswordChange}
                  defaultValue={password}
                  helperText={submitted && !password ? 'Password is required' : ''}
                  InputProps={{
                    endAdornment: <HidePasswordAdornment
                      showPassword={showPassword}
                      onClick={handleShowPassword}
                    />
                  }}
                />
              </FormControl>
              <FormControl component="div" margin="normal" fullWidth>
                <TextField
                  id="confirm"
                  name="confirm"
                  type={showPassword ? 'text' : 'password'}
                  label="confirm"
                  autoComplete="confirm-password"
                  variant="outlined"
                  fullWidth
                  disabled={loading ? true : false}
                  onChange={handleConfirmChange}
                  defaultValue={confirm}
                  helperText={submitted && !confirm? 'Confirmation password is required': ''}
                />
              </FormControl>
              <div className={'UserActionButtons'}>
                <FormControl component="div" margin="normal">
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    size='medium'
                    disabled={loading ? true : false}
                  >Update Password</Button>
                </FormControl>
              </div>
            </form>

          </Grid>
        </Grid>
      </Paper>
    </Container>
  )
};

export default ResetPassword;
