import { feathersClient as feathers } from '../../helpers/service';

const createContactRequests = async (companyId, requestType, contactType, message) => {
  console.log("Sending contactRequest: ", requestType, companyId )
  companyId = companyId ? companyId : 0;
  const request = await feathers.service('contact-requests').create({
    'id': companyId,
    'requestType': requestType,
    'contactType': contactType,
    'message': message,
  });

  return request
}

export const contactRequestsServices = {
  createContactRequests,
};

