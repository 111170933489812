import React from 'react';
import { Snackbar } from '@mui/material';

class ErrorBoundry extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      open: false,
      hasError: false,
      errorMessage: this.props.errorMessage || 'Something went wrong!',
    };

    this.handleClose.bind(this);
  }

  static getDerivedStateFromError(error) {
    // update state so the next render will show the fallback
    return { hasError: true, open: true};
  }

  componentDidCatch(error, errorInfo){
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
    // console.log(error, errorInfo)
  }

  handleClose = () => {
    this.setState({ ...this.state, open: false});
  };

  render(){
    if(this.state.hasError){
      // You can render any custom fallback UI
      return (
        <div>
          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center'}}
            open={this.state.open}
            onClose={this.handleClose}
            message={this.state.errorMessage}
          />
        </div>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundry;
