import {
  BANDWIDTH_FETCH,
} from '../constants';


const init = {
  data: []
}

export function zabbix(state = init, action) {
  switch (action.type) {
    case BANDWIDTH_FETCH.SUCCESS:
      return {
        ...state,
        data: action.bandwidth,
      };
    default:
      return state;
  }
}
