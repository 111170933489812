import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { compose } from 'redux';
import { useHistory } from 'react-router-dom';

import { alpha } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import DeleteIcon from '@mui/icons-material/Delete';

// Add User Dialog Box
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { DataGrid, useGridApiContext, useGridState } from '@mui/x-data-grid';

import Autocomplete from '@mui/material/Autocomplete';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import { roleTypes, actionTypes } from './';

import {
  createErrorMessageSelector,
  createLoadingSelector
} from '../../helpers/selectors';

import {
  userActions,
} from '../../redux/actions';



/* When we are ready to add actions back in and split those up we can add this
 * drop down back into our react DOM
  <TextField
    margin="dense"
    id="action"
    select
    name="action"
    label="Action"
    type="text"
    variant="standard"
    fullWidth
    onChange={handleChange}
  >
    {actionTypes.map((action) => (
      <MenuItem key={action.value} value={action.value}>{action.label}</MenuItem>
    ))}
  </TextField>
*/



const useStyles = makeStyles((theme) => ({
  root: {
    //paddingLeft: theme.spacing(2),
    //paddingRight: theme.spacing(1),
  },
  title: {
    flex: '1 1 100%',
  },
}));


const AddRoleDialog = (props) => {
  const [ confirmOpen, setConfirmOpen ] = useState(false);
  const [ role, setRole ] = useState({
    companyId: 0,
    role: '',
    action: '*'
  });
  const companies = [{companyId: 0, companyName: 'All Companies'}].concat(props.companies);

  const handleChange = (e, value) => {
    setRole({...role, [e.target.name]: e.target.value});
  }

  const handleCompanyChange = (e, value) => {
    setRole({...role, companyId: value.companyId});
  }

  const handleConfirm = () => {
    if ( role.companyId === 0 ) {
      setConfirmOpen(true);
    } else {
      props.onSubmit(role);
    }
  }

  const handleSubmit = () => {
    setConfirmOpen(false);
    props.onSubmit(role);
  }

  return (
    <div>
      <Dialog open={props.open} onClose={props.onClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Add Role</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Add a new role from the list below to the user.
          </DialogContentText>
          <Autocomplete
            id="companyId"
            onChange={handleCompanyChange}
            options={companies}
            getOptionLabel={(company) => company.companyName ?? ''}
            renderInput={(params) => (
              <TextField
                {...params}
                autoFocus
                id="companyId"
                name="companyId"
                label="Company"
                type="text"
                variant="standard"
                fullWidth
                onChange={handleChange}
              />
            )}
          />
          <TextField
            margin="dense"
            id="role"
            select
            name="role"
            label="Role"
            type="text"
            variant="standard"
            fullWidth
            onChange={handleChange}
          >
            {roleTypes.map((role) => (
              <MenuItem key={role.value} value={role.value}>{role.label}</MenuItem>
            ))}
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClose} color="error">
            Cancel
          </Button>
          <Button onClick={handleConfirm} color="secondary">
            Add Role
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={confirmOpen} onClose={ () => setConfirmOpen(false)}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to give this user access to all properties?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={ () => setConfirmOpen(false) } color="error">No Thanks!</Button>
          <Button onClick={ handleSubmit } color="secondary">Confirm</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const UserRolesToolbar = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const apiRef = useGridApiContext();

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAddUserRoleClick = (role) => {
    // TODO: Opens a model to build a new role
    role.userId = props.userId;
    dispatch(userActions.userRolesAddRequest(role));
    setOpen(false);
  };

  const handleRemoveUserRoleClick = (e) => {
    const selected = apiRef.current.getSelectedRows();
    const userRolesIds = Array.from(selected, ([key, value]) => value.id);
    dispatch(userActions.userRolesRemoveRequest(userRolesIds));
  };

  return <>
    <Toolbar>
      <Typography
        sx={{ flex: '1 1 100%' }}
        variant="h6"
        id="tableTitle"
        component="div"
      >Roles</Typography>
      <Tooltip title="Add Role">
        <IconButton aria-label="person list" onClick={handleOpen} size="large">
          <GroupAddIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Remove Role">
        <IconButton aria-label="person list" onClick={handleRemoveUserRoleClick} size="large">
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    </Toolbar>
    <AddRoleDialog
      open={open}
      onSubmit={handleAddUserRoleClick}
      onClose={handleClose}
      onOpen={handleOpen}
      companies={props.companies}
    />
  </>;
};

export default UserRolesToolbar;
