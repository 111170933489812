/* eslint-disable no-use-before-define */
import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { compose } from 'redux';
import { withRouter, Redirect } from 'react-router-dom';

// Actions
import { companiesActions } from '../../redux/actions';
import {
  createErrorMessageSelector,
  createLoadingSelector
} from '../../helpers/selectors';

// Material-UI
import { alpha } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import TextField from '@mui/material/TextField';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';


const useStyles = makeStyles((theme) => ({
  search: {
    flexGrow: 1,
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    [theme.breakpoints.up('md')]: {
      //marginLeft: theme.spacing(1),
      //width: 'auto',
      width: '30ch',
      marginLeft: 'auto',
    },
    [theme.breakpoints.up('lg')]: {
      width: '70ch',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    transition: theme.transitions.create('width'),
    marginLeft: theme.spacing(4),
    width: '100%',
  },
}));

function CompanySearch(props) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const companies = useSelector(state => state.companies);
  const loading = useSelector(createLoadingSelector(['COMPANIES_FETCH']))
  const errorMessage = useSelector(createErrorMessageSelector(['COMPANIES_FETCH']));
  const company = useSelector(state => state.company);

  const [open, setOpen] = useState(false);
  const [companyList, setCompanyList] = useState(companies.data);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [label] = useState("Select a Company");


  const onFirstLoad = () => {
    dispatch(companiesActions.companiesFetchRequest());
  }
  useEffect(onFirstLoad, []);

  const companiesUpdate = () => {
    if (companies?.data) {
      setCompanyList(companies.data);
    } else {
      setCompanyList([]);
    }
  };
  useEffect(companiesUpdate, [companies]);

  // TODO: May remove this if we dont need to display the current company
  // selected on page reloads
  const companyUpdate = () => {
    if (company) {
      setSelectedCompany(company.companyId);
    }
  };
  useEffect(companyUpdate, [company]);

  const handleChange = (e, value) => {
    if (value && value.companyId) {
      props.history.push('/company/'+value.companyId);
    }
  };

  return (
    <Autocomplete
      id="companySearch"
      options={companyList}
      onChange={handleChange}
      loading={loading}
      autoHighlight
      autoComplete
      openOnFocus
      getOptionLabel={(option) => option.companyName ?? ''}
      classes={{
        root: classes.search,
        inputRoot: classes.inputRoot,
        input: classes.inputInput,
        popper: classes.popper,
      }}
      renderInput={(params) => (
        <>
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <TextField
            {...params}
            placeholder="Search..."
            variant="outlined"
            InputLabelProps={{ shrink: false }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        </>
      )}
    />
  );
}
/*

          <TextField
            {...params}
            label="Search..."
            variant="outlined"
            InputLabelProps={{ shrink: false }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />

          <InputBase
            placeholder="Search…"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            inputProps={{ 'aria-label': 'search' }}
          />


inputValue={[companyList.find(e => e.companyId === selectedCompany)]}
inputValue={[companyList.find(e => e.companyId === selectedCompany)]}
open={open}
onOpen={() => setOpen(true)}
onClose={() => setOpen(false)}
*/
export default compose(withRouter)(CompanySearch)
