import {
  AUTHENTICATION_LOGIN,
  AUTHENTICATION_LOGOUT,
  AUTHENTICATION_REAUTH,
  AUTHENTICATION_VERIFY_EMAIL,
  AUTHENTICATION_VERIFY_SIGNUP,
  AUTHENTICATION_RESEND_VERIFY_SIGNUP,
  AUTHENTICATION_SEND_RESET_EMAIL,
  AUTHENTICATION_RESET_PASSWORD,
  AUTHENTICATION_RESET_PASSWORD_TOKEN,
} from '../constants';

let user = JSON.parse(localStorage.getItem('user'));
const initialState = user ? { loggedIn: true, user } : {};

export function authentication(state = initialState, action) {
  switch (action.type) {
    case AUTHENTICATION_LOGIN.REQUEST:
      return { ...state, };
    case AUTHENTICATION_LOGIN.SUCCESS:
      return {
        ...state,
        loggedIn: true,
        user: action.user,
      };
    case AUTHENTICATION_LOGIN.FAILURE:
      return {
        ...state,
        loggedIn: false
      };
    case AUTHENTICATION_REAUTH.REQUEST:
      return { ...state, };
    case AUTHENTICATION_REAUTH.SUCCESS:
      return {
        ...state,
        loggedIn: true,
      };
    case AUTHENTICATION_REAUTH.FAILURE:
      return {
        ...state,
        loggedIn: false,
      };
    case AUTHENTICATION_LOGOUT.SUCCESS:
      return {
        ...state,
        loggedIn: false,
        user: {}
      };
    case AUTHENTICATION_VERIFY_SIGNUP.SUCCESS:
      return {
        ...state,
        loggedIn: false,
        user: action.user
      }
    case AUTHENTICATION_VERIFY_SIGNUP.FAILURE:
      return {
        ...state,
        loggedIn: false,
        user: {}
      }
    default:
      return state
  }
}
