var passwordRotation = require('./password-rotation');
var zabbix = require('./zabbix');

module.exports = {
  // password rotation
  setCompanyPasswordNotifyEmailList:
    passwordRotation.setCompanyPasswordNotifyEmailList,
  companyTriggerPasswordRotation:
    passwordRotation.companyTriggerPasswordRotation,

  // zabbix
  // explicitly listing functions is redundant but I like them here :>
  zabbix: zabbix
};
