import React from 'react';

import { alpha } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import OvatnAppBar from '../hoc/OvatnAppBar';
import Drawer from '@mui/material/Drawer';
import Paper from '@mui/material/Paper';

const useStyles = makeStyles((theme) => ({
  root: {},
  copywrite: {
    [theme.breakpoints.down('xl')]: {
      display: "none",
    },
  },
}));

function Copyright() {
  const classes = useStyles();

  return (
    <Drawer
      variant="persistent"
      anchor="bottom"
      open={true}
      className={classes.copywrite}
    >
      <Typography variant="body2" color="textSecondary" align="center" anchor="bottom">
        {'Copyright © '}
        <Link color="inherit" href="https://material-ui.com/" underline="hover">
          dashboard.ovationnetworks.com
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    </Drawer>
  );
}

class View extends React.Component {
  constructor(props){
    super(props);
  }

  render(){
    return (
      <Paper className='App-Wrapper'>
        <OvatnAppBar />
        <Container>
          <Copyright />
        </Container>
      </Paper>
    )
  }
}

export default View;
