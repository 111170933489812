import { call, put, race, delay, takeEvery } from 'redux-saga/effects';
import { createAction } from '../../helpers/actions'
import { serviceCall } from '../../helpers/effects';

import { authenticationService } from '../services';

import {
  AUTHENTICATION_LOGIN,
  AUTHENTICATION_LOGOUT,
  AUTHENTICATION_REAUTH,
  AUTHENTICATION_VERIFY_EMAIL,
  AUTHENTICATION_VERIFY_SIGNUP,
  AUTHENTICATION_RESEND_VERIFY_SIGNUP,
  AUTHENTICATION_SEND_RESET_EMAIL,
  AUTHENTICATION_RESET_PASSWORD,
  AUTHENTICATION_RESET_PASSWORD_TOKEN,
} from '../constants';

import { alertActions } from './';
//import { history } from '../../helpers';


export const authenticationActions = {
  loginRequest: (username, password) => createAction(AUTHENTICATION_LOGIN.REQUEST,
    { username: username, password: password }),
  loginSuccess: (user, token) => createAction(AUTHENTICATION_LOGIN.SUCCESS,
    { token: token, user: user }),
  loginFailure: (errorMessage) => createAction(AUTHENTICATION_LOGIN.FAILURE,
    { errorMessage: errorMessage }),

  logoutRequest: () => createAction(AUTHENTICATION_LOGOUT.REQUEST),
  logoutSuccess: () => createAction(AUTHENTICATION_LOGOUT.SUCCESS),
  logoutFailure: (errorMessage) => createAction(AUTHENTICATION_LOGOUT.FAILURE,
    { errorMessage: errorMessage }),

  reauthRequest: () => createAction(AUTHENTICATION_REAUTH.REQUEST),
  reauthSuccess: () => createAction(AUTHENTICATION_REAUTH.SUCCESS),
  reauthFailure: (errorMessage) => createAction(AUTHENTICATION_REAUTH.FAILURE,
    { errorMessage: errorMessage }),

  verifyEmailRequest: (token) => createAction(AUTHENTICATION_VERIFY_EMAIL.REQUEST, { token: token }),
  verifyEmailSuccess: () => createAction(AUTHENTICATION_VERIFY_EMAIL.SUCCESS, {}),
  verifyEmailFailure: (errorMessage) => createAction(AUTHENTICATION_VERIFY_EMAIL.FAILURE,
    { errorMessage: errorMessage }),


  verifySignupRequest: (token, password) => createAction(AUTHENTICATION_VERIFY_SIGNUP.REQUEST, { token: token, password: password }),
  verifySignupSuccess: (user) => createAction(AUTHENTICATION_VERIFY_SIGNUP.SUCCESS, { user: user }),
  verifySignupFailure: (errorMessage) => createAction(AUTHENTICATION_VERIFY_SIGNUP.FAILURE,
    { errorMessage: errorMessage }),

  resendVerifySignupRequest: (email) => createAction(AUTHENTICATION_RESEND_VERIFY_SIGNUP.REQUEST,
    { email: email }),
  resendVerifySignupSuccess: () => createAction(AUTHENTICATION_RESEND_VERIFY_SIGNUP.SUCCESS),
  resendVerifySignupFailure: (errorMessage) => createAction(AUTHENTICATION_RESEND_VERIFY_SIGNUP.FAILURE,
    { errorMessage: errorMessage }),

  sendResetEmailRequest: (email) => createAction(AUTHENTICATION_SEND_RESET_EMAIL.REQUEST,
    { email: email }),
  sendResetEmailSuccess: () => createAction(AUTHENTICATION_SEND_RESET_EMAIL.SUCCESS),
  sendResetEmailFailure: (errorMessage) => createAction(AUTHENTICATION_SEND_RESET_EMAIL.FAILURE,
    { errorMessage: errorMessage }),

  resetPasswordRequest: () => createAction(AUTHENTICATION_RESET_PASSWORD.REQUEST),
  resetPasswordSuccess: () => createAction(AUTHENTICATION_RESET_PASSWORD.SUCCESS),
  resetPasswordFailure: (errorMessage) => createAction(AUTHENTICATION_RESET_PASSWORD.FAILURE,
    { errorMessage: errorMessage }),

  resetPasswordWithTokenRequest: (token, password) => createAction(AUTHENTICATION_RESET_PASSWORD_TOKEN.REQUEST,
    { token: token, password: password }),
  resetPasswordWithTokenSuccess: () => createAction(AUTHENTICATION_RESET_PASSWORD_TOKEN.SUCCESS),
  resetPasswordWithTokenFailure: (errorMessage) => createAction(AUTHENTICATION_RESET_PASSWORD_TOKEN.FAILURE,
    { errorMessage: errorMessage }),
};



export function* login(action) {
  try {
    const {username, password} = action;
    const {token, user} = yield call(authenticationService.login, username, password);
    yield put(authenticationActions.loginSuccess(user, token));
  } catch (e) {
    yield put(authenticationActions.loginFailure(e.message));
    yield put(alertActions.error(`Failed to login: ${e.message}`, action.type));
  }
}

export function* logout() {
  try {
    yield call(authenticationService.logout);
    yield put(authenticationActions.logoutSuccess());
  } catch (e) {
    yield put(authenticationActions.logoutFailure(e.message));
  }
}

export function* reauth() {
  try {
    yield call(authenticationService.reauth);
    yield put(authenticationActions.reauthSuccess());
  } catch (e) {
    yield put(authenticationActions.reauthFailure(e.message));
    yield put(authenticationActions.logoutRequest());
  }
}

export function* verifyEmail(action) {
  try {
    const results = yield call(authenticationService.verifyEmail, action.token);
    yield put(authenticationActions.verifyEmailSuccess(results));
  } catch (e) {
    yield put(authenticationActions.verifyEmailFailure(e.message));
    yield put(alertActions.error(`Failed to verify email: ${e.message}`, action.type));
  }
}


export function* verifySignup(action) {
  try {
    const user = yield call(authenticationService.verifySignup, action.token, action.password);
    yield put(authenticationActions.verifySignupSuccess(user));
  } catch (e) {
    yield put(authenticationActions.verifySignupFailure(e.message));
    yield put(alertActions.error(`Failed to verify signup: ${e.message}`, action.type));
  }
}

export function* resendVerifySignup(action) {
  try {
    const results = yield call(authenticationService.resendVerifySignup, action.email);
    yield put(authenticationActions.resendVerifySignupSuccess(results));
  } catch (e) {
    yield put(authenticationActions.resendVerifySignupFailure(e.message));
    yield put(alertActions.error(`Failed to resend verify signup email: ${e.message}`, action.type));
  }
}


export function* sendResetEmail(action) {
  try {
    const results = yield call(authenticationService.sendResetEmail, action.email);
    yield put(authenticationActions.sendResetEmailSuccess(results));
  } catch (e) {
    yield put(authenticationActions.sendResetEmailFailure(e.message));
    yield put(alertActions.error(`Failed to send password reset: ${e.message}`, action.type));
  }
}

export function* resetPassword(email, oldPassword, password) {
  try {
    const results = yield call(authenticationService.resetPassword, email, oldPassword, password);
    yield put(authenticationActions.resetPasswordSuccess());
  } catch (e) {
    yield put(authenticationActions.resetPasswordFailure(e.message));
    yield put(alertActions.error(`Failed to reset password: ${e.message}`, action.type));
  }
}

export function* resetPasswordWithToken(action) {
  try {
    const {token, password} = action;
    const results = yield call(authenticationService.resetPasswordWithToken, token, password);
    yield put(authenticationActions.resetPasswordWithTokenSuccess());
  } catch (e) {
    yield put(authenticationActions.resetPasswordWithTokenFailure(e.message));
    yield put(alertActions.error(`Failed to reset password: ${e.message}`, action.type));
  }
}


export const authenticationSagas = [
  takeEvery(AUTHENTICATION_LOGIN.REQUEST, login),
  takeEvery(AUTHENTICATION_LOGOUT.REQUEST, logout),
  takeEvery(AUTHENTICATION_REAUTH.REQUEST, reauth),
  takeEvery(AUTHENTICATION_VERIFY_EMAIL.REQUEST, verifyEmail),
  takeEvery(AUTHENTICATION_VERIFY_SIGNUP.REQUEST, verifySignup),
  takeEvery(AUTHENTICATION_RESEND_VERIFY_SIGNUP.REQUEST, resendVerifySignup),
  takeEvery(AUTHENTICATION_SEND_RESET_EMAIL.REQUEST, sendResetEmail),
  takeEvery(AUTHENTICATION_RESET_PASSWORD.REQUEST, resetPassword),
  takeEvery(AUTHENTICATION_RESET_PASSWORD_TOKEN.REQUEST, resetPasswordWithToken),
]
