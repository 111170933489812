import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

// Material UI components
import { useTheme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import useMediaQuery from '@mui/material/useMediaQuery';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';


import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const useStyles = makeStyles((theme) => ({
  root: {},
  contactMeButton: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  modalClose: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

// TODO: Cancel back button request and just hide the form.  A Close button?

// Need to determin if the current timestamp is within current working hours
// First get current time (hour) in UTC
let now = moment().utc().format('HH');
// Current work hours are from 13 UTC to 22 UTC
let betweenWorking = 13 < now && now < 22 ? null : 'We are currently outside office hours. Our current office hours are 8AM to 5PM CST';

const neutralizeBack = (callback) => {
  window.history.pushState(null, "", window.location.href);
  window.onpopstate = () => {
    window.history.pushState(null, "", window.location.href);
    callback();
  };
};

const revivalBack = () => {
  window.onpopstate = undefined;
  window.history.back();
};


const DefaultButton = ({buttonProps = {}, disabled = false, handleClickOpen = null}) => (
  <Button
    variant="contained"
    color="secondary"
    disabled={disabled}
    onClick={handleClickOpen}
    {...buttonProps}
  >
    {buttonProps.label ?? "Contact Me"}
  </Button>
);

function ContactMe ({
  children = null,
  onSend = null,
  disabled = false,
  title = null,
  description = null,
  showEmail = false,
  ...buttonProps
}) {
  const classes = useStyles();
  const theme = useTheme();

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [contactType, setContactType] = useState('email');
  const [contactTypeValue, setContactTypeValue] = useState(null);
  const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));

  const handleClickOpen = () => {
    setOpen(true);
    neutralizeBack(handleClose);
  };

  const handleClose = () => {
    setOpen(false);
    revivalBack();
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleContactTypeChange = (e) => {
    setContactType(e.target.value)
  };

  const onSubmit = () => {
    setMessage('');
    handleClose();
    onSend(contactType, message)
  };

  return <>
    { children ? children : <DefaultButton buttonProps={buttonProps} disabled={disabled} handleClickOpen={handleClickOpen} />}
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">{"Email Account Manager"}
        <IconButton
          className={classes.modalClose}
          aria-label='close'
          onClick={handleClose}
          size="large">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Your account manager will attempt to contact you as soon as possible.
        </DialogContentText>
        { showEmail && (
        <FormControl component="span" margin="normal" fullWidth={true}>
          <InputLabel id="contact-type-select-label">How would you like to be contacted?</InputLabel>
          <Select
            labelId='contact-type-select-label'
            value={contactType}
            onChange={handleContactTypeChange}
          >
            <MenuItem value="email">Email</MenuItem>
            <MenuItem value="phone">Phone</MenuItem>
            <MenuItem value="sms">SMS</MenuItem>
          </Select>
        </FormControl>
        )}
        <FormControl component="div" margin="normal" fullWidth={true}>
          <TextField
            label={"Please let us know how we can help."}
            multiline
            fullWidth={true}
            rows={8}
            variant="outlined"
            value={message}
            onChange={handleMessageChange}
            helperText={betweenWorking}
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={()=>{setMessage('')}} color="secondary">
          Clear Message
        </Button>
        <Button autoFocus onClick={onSubmit} color="secondary">
          Send Message
        </Button>
      </DialogActions>
    </Dialog>
  </>;
}

export default ContactMe;
