var zabbix = require('../../helpers/webapi/zabbix');

const fetchBandwidth = async (uid) => {
  try {
    const hosts = await zabbix.hosts(uid);

    const wanInterfaces = await Promise.all(
      hosts.map(
        async host => await zabbix.wanInterface(uid, host.name)
      )
    );

    const bandwidthUp = await Promise.all(
      wanInterfaces.map(
        async iface => await zabbix.bandwidthUp24(uid, iface.host, iface.value)
      )
    );

    const bandwidthDown = await Promise.all(
      wanInterfaces.map(
        async iface => await zabbix.bandwidthDown24(uid, iface.host, iface.value)
      )
    );

    return organizeResults({
      hosts: hosts,
      wanInterfaces: wanInterfaces,
      bandwidthHistory: {
        up: bandwidthUp,
        down: bandwidthDown,
      }
    });
  } catch (err) {
    console.error('Error', error);
    return err;
  }
}

const organizeResults = (results) => {
  return results.hosts.map((host) => {
    var hostName = host.name;

    var bandwidthUp = results.bandwidthHistory.up
      .filter(function justThisHostUp(up){
        return up.host === hostName;
    });

    var bandwidthDown = results.bandwidthHistory.down
      .filter(function justThisHostDown(down){
        return down.host === hostName;
    });

    return {
      host: hostName,
      bandwidth: {
        up: bandwidthUp[0].history,
        down: bandwidthDown[0].history
      }
    };
  });
}


export const zabbixServices = {
  fetchBandwidth,
};

