import React, { useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { compose } from 'redux';
import { withRouter, Redirect } from 'react-router-dom';

import { usersActions } from '../../redux/actions';
import {
  createErrorMessageSelector,
  createLoadingSelector
} from '../../helpers/selectors';

import { alpha } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import { DataGrid } from '@mui/x-data-grid'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100%'
  }
}));


const headers = [
  { field: 'id', headerName: 'ID', width: 70 },
  { field: 'displayName', headerName: 'Display Name', flex: 0.5},
  { field: 'email', headerName: 'Email', flex: 1 },
  { field: 'isVerified', headerName: 'Verified?', type: 'bool', width: 75 },
  { field: 'lastActive', headerName: 'Last Active', width: 100},
]


const UserList = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();


  // Local component state
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event) => {
    setPage(event.page);
  };

  const handleChangePageSize = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  return (
    <div style={{ display: 'flex', height: '100%' }}>
      <div style={{ flexGrow: 1 }}>
        <DataGrid
          loading={props.loading}
          autoHeight
          disableColumnMenu
          rows={props.users}
          columns={headers}
          pageSize={10}
          onPageChange={handleChangePage}
          onPageSizeChange={handleChangePageSize}
        />
      </div>
    </div>
  );
};

//export default compose(withRouter)(UserList);
export default UserList;
