import React, { useState, useEffect } from 'react';

// Material UI components
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import AttachMoney from '@mui/icons-material/AttachMoney';

const Billing = (props) => {
  const [token, setToken] = useState(props.token ?? 'unknown');
  const [dueDate, setDueDate] = useState(props.dueDate ?? '12/23/2021');

  useEffect(() => {
    setToken(token);
  }, [token])

  let ksPath = 'https://app.kickserv.com/a8fe0c/self_service?token=' + [token];

  return (
    <div style={{padding:'15px'}}>
        <Grid container spacing={2} direction="column" justifyContent='center' alignItems='center'>
          <Grid item xs={12} sm={6} fixed='true'>
            <Typography variant="b1">Make payments and view work history</Typography>
          </Grid>
          <Grid item xs={12} sm={6} fixed='true'>
            <Button
              variant="contained"
              color="secondary"
              href={ksPath}
              target="_new">Payment Portal</Button>
          </Grid>
          <Grid item xs={12} sm={6} fixed='true' style={{textAlign: 'center'}}>
            <Typography align='center' variant="caption" >Payment history may be inaccurate, questions or concerns contact accounting</Typography>
          </Grid>
        </Grid>
    </div>
  );
}

export default Billing;
