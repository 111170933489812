import { feathersClient as feathers } from '../../helpers/service';
import AuthManagement from 'feathers-authentication-management/src/client';

const authManagement = new AuthManagement(feathers);

export const login = async (email, password) => {
  const {accessToken, user} = await feathers.authenticate({
    strategy: 'local',
    email: email,
    password: password
  });

  //const payload = await feathers.passport.verifyJWT(token);
  //console.log("after verify");
  //const user = await feathers.service('users').get(payload.usersId);

  localStorage.setItem('user', JSON.stringify(user));
  return {token: accessToken, user: user};
}

export const logout = async () => {
  // remove user from local storage to log user out
  await feathers.logout();
  localStorage.removeItem('user');
}

export const reauth = async () => {
  await feathers.reAuthenticate();
}

export const verifyEmail = async (token) => {
  return await authManagement.verifySignupLong(token);
}

export const verifySignup = async (token, password) => {
  //return await authManagement.verifySignupSetPasswordLong(token, password);
  return await feathers.service('authManagement').create({
    action: 'verifySignupSetPasswordLong',
    value: {
      token: token,
      password: password
    }
  });
}

export const resendVerifySignup = async (email) => {
  return await feathers.service('authManagement').create({
    action: 'resendVerifySignup',
    value: { email: email }
  });
}

export const sendResetEmail = async (email) => {
  return await authManagement.sendResetPwd({email});
}

export const resetPasswordWithToken = async(token, password) => {
  return await authManagement.resetPwdLong(token, password);
}

export const resetPassword = async (email, oldPassword, password) => {
  return await authManagement.passwordChange(oldPassword, password, { email: email } );
}

export const authenticationService = {
  login,
  logout,
  reauth,
  verifyEmail,
  verifySignup,
  resendVerifySignup,
  sendResetEmail,
  resetPasswordWithToken,
  resetPassword
};

