import React from 'react';
import { Card } from '@mui/material';
import { CardActions } from '@mui/material';
import { CardContent } from '@mui/material';
import { Button } from '@mui/material';
import { Typography } from '@mui/material';

const About = () => {
  return (
    <Card className={'About-card'} variant="outlined">
      <CardContent>
        <Typography className={'About-Title'} color="textSecondary" gutterBottom>
          About Ovation Networks
        </Typography>
        <Typography variant="h5" component="h2">
          Something
        </Typography>
        <Typography variant="body2" component="p">
          Something here.
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small">Contact US</Button>
      </CardActions>
    </Card>
  );
};

export default About;
