import { feathersClient as feathers } from '../../helpers/service';

const getItemStatusValue = (filter) => {
  const status = { "all": null, "open": { $nin: [6, 10] }, "closed": {$in: [6, 10]}  }
  return status[filter];
}


// options = { search, filter, order, sort, page, size }
const fetchTickets = async (companyId, options) => {
  const defaults = {
    search: null,
    filter: {},
    order: 0,
    sort: 'itemId',
    page: 1,
    size: 10
  };
  const { search, filter, order, sort, page, size } = Object.assign({}, defaults, options);

  const skip = page * size;

  companyId = companyId ? companyId : 0;

  const query = {
    query: {
      itemCompanyId: companyId,
      $sort: { [sort]: order },
      $limit: size,
      $skip: skip
    }
  };

  if (search) {
    query.query['$or'] = [
      {itemSummary: { $like: `%${search}%` }},
      {itemId: { $like: `%${search}%` }},
      {itemRequestorRoom: { $like: '%' + search + '%' }},
    ];
  }

  if (filter) {
    // Set the date range if defined
    if (filter.range) {
      query.query['itemModified'] = {
        $gte: filter.range.startDate,
        $lte: filter.range.endDate,
      }
    }
    if (filter.filter && filter.filter != "all") {
      query.query['itemStatus'] = getItemStatusValue(filter.filter);
    }
  }

  const tickets = await feathers.service('helpdesk-items').find(query);

  return tickets.data;
}

const fetchTicketsCount = async (companyId, options) => {
  const defaults = {
    search: null,
    filter: {},
  };
  const { search, filter } = Object.assign({}, defaults, options);

  companyId = companyId ? companyId : 0;

  const query = {
    query: {
      itemCompanyId: companyId,
      //itemStatus: { $ne: 6 },
      $limit: 0
    }
  };

  if (search) {
    query.query['$or'] = [
      {itemSummary: { $like: `%${search}%` }},
      {itemId: { $like: `%${search}%` }},
      {itemRequestorRoom: { $like: '%' + search + '%' }},
    ];
  }

  if (filter) {
    // Set the date range if defined
    if (filter.range) {
      query.query['itemModified'] = {
        $gte: filter.range.startDate,
        $lte: filter.range.endDate,
      }
    }
    if (filter.filter && filter.filter != "all") {
      query.query['itemStatus'] = getItemStatusValue(filter.filter);
    }
  }

  const tickets = await feathers.service('helpdesk-items').find(query);
  return tickets.total;
};

export const helpdeskServices = {
  fetchTickets,
  fetchTicketsCount,
};

