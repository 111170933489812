import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { compose } from 'redux';
import { useHistory } from 'react-router-dom';

import { alpha } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { DataGrid } from '@mui/x-data-grid';

import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

import TextField from '@mui/material/TextField';

import {
  createErrorMessageSelector,
  createLoadingSelector
} from '../../helpers/selectors';

import LinearProgress from '@mui/material/LinearProgress';

// Components
import Section from '../../components/Section';
import ErrorBoundry from '../../components/ErrorBoundry';

import UserList from '../../hoc/UserList';

import {
  usersActions,
} from '../../redux/actions';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(2),
  },
}));

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  title: {
        flex: '1 1 100%',
  },
}));


const UsersToolbar = (props) => {
  const dispatch = useDispatch();
  const classes = useToolbarStyles();
  const history = useHistory();

  const handleAddUserClick = (e) => {
    history.push('/user');
  };

  return (
    <Toolbar className={classes.root}>
      <Typography
        className={classes.title}
        variant="h6"
        id="tableTitle"
        component="div"
      >My Users</Typography>
      <TextField
        id="userSearch"
        variant="standard"
        value={props.searchValue || ''}
        onChange={props.onChange}
        placeholder="Search..."
      />
      <Tooltip title="Add User">
        <IconButton aria-label="person list" onClick={handleAddUserClick} size="large">
          <PersonAddIcon />
        </IconButton>
      </Tooltip>
    </Toolbar>
  );

};


const headers = [
  { field: 'id', headerName: 'ID', width: 70 },
  { field: 'displayName', headerName: 'Display Name', flex: 0.5},
  { field: 'email', headerName: 'Email', flex: 1 },
  { field: 'isVerified', headerName: 'Verified?', width: 135 },
  { field: 'lastActive', headerName: 'Last Active', width: 150},
]


function Users(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();

  // Local component state
  const [search, setSearch] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sort, setSort] = useState('id');
  const [order, setOrder] = useState(1);

  const loading = {
    users: useSelector(createLoadingSelector(['USERS_FETCH'])),
    total: useSelector(createLoadingSelector(['USERS_COUNT_FETCH'])),
  }
  const errorMessage = {
    users: useSelector(createErrorMessageSelector(['USERS_FETCH'])),
    total: useSelector(createErrorMessageSelector(['USERS_COUNT_FETCH'])),
  }

  const users = useSelector(state => state?.users?.users);
  const total = useSelector(state => state?.users?.total);

  // On first load, only runs once
  const onFirstLoad = () => {
    dispatch(usersActions.usersCountFetchRequest({ search: search }));
    dispatch(usersActions.usersFetchRequest({ search: search, sort: sort, order: order, page: page, size: rowsPerPage }));
  };
  useEffect(onFirstLoad, [])

  const onUserSearchChange = () => {
    setPage(0);
    dispatch(usersActions.usersCountFetchRequest({ search: search }));
    dispatch(usersActions.usersFetchRequest({ search: search, sort: sort, order: order, page: 0, size: rowsPerPage }));
  };
  useEffect(onUserSearchChange, [search]);

  const onSortModelChange = () => {
    dispatch(usersActions.usersCountFetchRequest({ search: search }));
    dispatch(usersActions.usersFetchRequest({ search: search, sort: sort, order: order, page: page, size: rowsPerPage }));
  }
  useEffect(onSortModelChange, [sort, order]);

  const handleChangePage = (e) => {
    setPage(e);
    dispatch(usersActions.usersFetchRequest({ search: search, sort: sort, order: order, page: e, size: rowsPerPage }));
  };

  const handleChangePageSize = (e) => {
    setRowsPerPage(e); // TODO: not confirmed
    dispatch(usersActions.usersFetchRequest({ search: search, sort: sort, order: order, page: page, size: e }));
  };

  const handleSortModelChange = (newModel) => {
    if ( newModel && Array.isArray(newModel) && newModel.length > 0 ) {
      setSort(newModel[0]['field']);
      setOrder(newModel[0]['sort'] === 'asc' ? 1 : 0);
    }
  };

  const handleDoubleClick = (e) => {
    // TODO: Validate that the user ID is real? or a number or dont care?
    history.push('/user/'+ e.id);
  };

  const handleUserSearchChange = (e) => {
    setSearch(e.target.value);
  };

  return (
    <Container maxWidth="lg">
      <div className={classes.root}>
        <ErrorBoundry errorMessage={errorMessage.users}>
          <Grid container spacing={3}>
            <Section title={''} xs={12}>
              <UsersToolbar
                searchValue={search}
                onChange={handleUserSearchChange}
              />
              <DataGrid
                loading={loading.users}
                autoHeight
                disableColumnMenu
                paginationMode="server"
                filterMode="server"
                sortingMode="server"
                sortModel={[ { field: sort, sort: order ? 'asc' : 'desc' } ]}
                onSortModelChange={handleSortModelChange}
                columns={headers}
                rows={users}
                rowCount={total ?? 0}
                page={page}
                pageSize={rowsPerPage}
                rowsPerPageOptions={[rowsPerPage]}
                onPageChange={handleChangePage}
                onPageSizeChange={handleChangePageSize}
                onRowDoubleClick={handleDoubleClick}
              />
            </Section>
          </Grid>
        </ErrorBoundry>
      </div>
    </Container>
  )
}

export default Users
