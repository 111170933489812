import feathers from '@feathersjs/client';
import socketio from '@feathersjs/socketio-client';
import io from 'socket.io-client';
import auth from '@feathersjs/authentication-client';

import config from '../config';

// Set the websocket api host/path
const socket = io(config.ws.address, {
  transports: ['websocket']
});

export const feathersClient = feathers()
  .configure(socketio(socket))
  .configure(auth());


/*
const socket = io(config.ws.address, {transports: ['websocket']});
export const feathersClient = feathers()
    .configure(feathers.socketio(socket));
    */
