import { feathersClient as feathers } from '../../helpers/service';

const fetchUsersCount = async (options) => {
  const defaults = {
    search: null,
    filter: {},
  };
  const { search, filter } = Object.assign({}, defaults, options);

  const query = {
    query: {
      $limit: 0
    }
  };

  if (search) {
    query.query['$or'] = [
      {displayName: { $like: `%${search}%` }},
      {email: { $like: `%${search}%` }},
    ];
  }

  const users = await feathers.service('users').find(query);
  return users.total;
};

const fetchUsers = async (options) => {
  const defaults = {
    search: null,
    filter: {},
    sort: 'id',
    order: 0,
    page: 1,
    size: 10
  };
  const { search, filter, sort, order, page, size } = Object.assign({}, defaults, options);

  const skip = page * size;

  const query = {
    query: {
      $sort: { [sort]: order },
      $limit: size,
      $skip: skip
    }
  };

  if (search) {
    query.query['$or'] = [
      {displayName: { $like: `%${search}%` }},
      {email: { $like: `%${search}%` }},
    ];
  }

  const users = await feathers.service('users').find(query);
  return users.data;
}

export const usersServices = {
  fetchUsersCount,
  fetchUsers,
};
