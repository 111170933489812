import React, { useState, useEffect } from 'react';
import _ from 'lodash';

// Material UI components
import { styled } from '@mui/styles';
import Grid from '@mui/material/Grid';

// Ovation Components
import AddressBlock from '../AddressBlock';
import TelephoneNumber from '../TelephoneNumber';

const CompanyLogo = styled('img')( ({theme}) => ({
  height: 'auto',
  width: 'auto',
  margin: 'auto',
  display: 'block',
}));

const CompanyLogoContainer = styled('div')( ({theme}) => ({
  height: '100%',
  width: '100%',
  padding: 'auto',
}));


const CompanyInfo = ({
  companyId, displayName, address1, address2,
  cityname, statename, zip, phone1, phone2, fax
}) => {

  return (
    <Grid container spacing={3} justifyContent='flex-start' alignItems='center'>
      <Grid item xs >
        { companyId !== 0 ?
            <CompanyLogoContainer>
              <CompanyLogo src={'https://captive.ovatn.net/logos/' + companyId} />
            </CompanyLogoContainer>
          : ''
        }
      </Grid>
      <Grid item xs={12} sm={6} fixed='true'>
        <AddressBlock
          displayName={displayName}
          address1={address1}
          address2={address2}
          cityname={cityname}
          statename={statename}
          zip={zip}
        />
      </Grid>
      <Grid item xs >
        <TelephoneNumber phone1={phone1} phone2={phone2} fax={fax} />
      </Grid>
    </Grid>
  );
}

export default CompanyInfo;
