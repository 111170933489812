import {
  COMPANY_FETCH,
  COMPANY_UPDATE_EMAIL_LIST,
  COMPANY_CLEAR_ACTIVE,
} from '../constants';


export function company(state = {}, action) {
  switch (action.type) {
    case COMPANY_FETCH.SUCCESS:
      return {
        ...state,
        ...action.company,
      };
    case COMPANY_UPDATE_EMAIL_LIST.SUCCESS:
      return {
        ...state,
        ...action.company,
      };
    case COMPANY_CLEAR_ACTIVE:
      return {};
      break;

    default:
      return state;
  }
}
