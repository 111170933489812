import React from 'react';
import PropTypes from 'prop-types';
/*
  Box for showing status messages

  types:
    - standard - just regular
    - success - green to indicate happy news
    - error - scarey red box
    - secondary - greyed out box for minor alerts
*/

class Alert extends React.Component{
  render(){
    var _this = this;
    var TYPES = {
      STANDARD: 'standard',
      SUCCESS: 'success',
      ERROR: 'error',
      SECONDARY: 'secondary',
      WARNING: 'warning',
      INFO: 'info'
    };

    function getClasses(){
      var classes = ['alert-box'];

      switch(_this.props.type){
        case TYPES.SUCCESS: classes.push('success'); break;
        case TYPES.ERROR: classes.push('alert'); break;
        case TYPES.SECONDARY: classes.push('secondary'); break;
        case TYPES.WARNING: classes.push('warning'); break;
        case TYPES.INFO: classes.push('info'); break;
        default: classes.push('alert'); break;
      }

      if(_this.props.isRounded === true){
        classes.push('round');
      }

      return classes.join(' ');
    }

    return (
      <div className={getClasses()}>{
        this.props.children
      }</div>
    );
  }
};

Alert.propTypes = {
  type: PropTypes.string, // (optional) standard|success|error|secondary|warning|info
  isRounded: PropTypes.bool, // (optional) if true has rounded ends
}

export default Alert;
