import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { compose } from 'redux';
import { useHistory } from 'react-router-dom';

import makeStyles from '@mui/styles/makeStyles';
import { DataGrid } from '@mui/x-data-grid';

// User Roles Toolbar
//import Toolbar from '@mui/material/Toolbar';

import {
  createErrorMessageSelector,
  createLoadingSelector
} from '../../helpers/selectors';

import UserRolesToolbar  from './UserRolesToolbar';

import { userActions } from '../../redux/actions';

const useStyles = makeStyles((theme) => ({
  root: {
    //marginTop: theme.spacing(3),
    //padding: theme.spacing(2),
  },
}));

export const roleTypes = [
  {
    value: "rad",
    label: "Rad"
  },{
    value: "owner",
    label: "Owner"
  },{
    value: "manager",
    label: "Manager"
  },{
    value: "staff",
    label: "Staff"
  }
];

export const actionTypes = [
  {
    value: "*",
    label: "All"
  },{
    value: "find",
    label: "Find"
  },{
    value: "get",
    label: "Get"
  },{
    value: "create",
    label: "Create"
  },{
    value: "update",
    label: "Update"
  },{
    value: "patch",
    label: "Patch"
  },{
    value: "remove",
    label: "Remove"
  }
];



//TODO: either split the roles data into company name, role, action and display
//it on the client side. or have the server side build this dataset in the
//format we need to consume it? I think it should be done server side.
const headers = [
  { field: 'companyName', headerName: 'Company', flex: 2, sortable: false },
  { field: 'role', headerName: 'Role', flex: 1, sortable: false},
  { field: 'action', headerName: 'action', flex: 1, sortable: false}
]

function UserRoles({userId = 0}) {
  // Hooks
  const dispatch = useDispatch();
  const history = useHistory();

  // Local component state
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [data, setData] = useState([]);
  const [sort, setSort] = useState('companyId');
  const [order, setOrder] = useState(1);

  // Track redux loading and error states
  const loading = {
    user: useSelector(createLoadingSelector(['USER_FETCH'])),
    roles: useSelector(createLoadingSelector(['USER_ROLES_FETCH'])),
    total: useSelector(createLoadingSelector(['USER_ROLES_COUNT_FETCH'])),
  }
  const errorMessage = {
    user: useSelector(createErrorMessageSelector(['USER_FETCH'])),
    roles: useSelector(createErrorMessageSelector(['USER_ROLES_FETCH'])),
    total: useSelector(createErrorMessageSelector(['USER_ROLES_COUNT_FETCH'])),
  }

  // Track redux states
  // TODO: add error handling if users is undefined
  const user = useSelector(state => state?.user?.current);
  const roles = useSelector(state => state?.user?.roles?.data);
  const total = useSelector(state => state?.user?.roles?.total);
  const companies = useSelector(state => state?.companies?.data);

  // On first load, only runs once
  const onFirstLoad = () => {
    // Will trigger a userRolesCountFetchRequest because the userRoles will
    // return data.
    dispatch(userActions.userRolesCountFetchRequest(userId));
    dispatch(userActions.userRolesFetchRequest(userId, page, rowsPerPage));
  };
  useEffect(onFirstLoad, [])

  ///*
  // Update roles total count if the roles are updated (added or deleted role)
  const onUserRolesChange = () => {
    dispatch(userActions.userRolesCountFetchRequest(userId));
  };
  useEffect(onUserRolesChange, [roles]);
  //*/

  // Helper function to parse the companyName from companies list
  const getCompanyName = (id) => {
    if (companies) { // Test to see if it exists and is an array? what if id isn't found?
      if (id <= 0 || id ===  '*') { return 'All Companies'}
      const company = companies.find(company => company.companyId === id);
      if (!company) { return 'Unknown'; }
      return company?.companyName;
    }
  }

  // Update the data for display w/ the proper company name anytime role or
  // companies change (in case the role, or the companyName changed at some
  // point)
  const onDataChange = () => {
    if (roles && roles.length > 0 && companies.length > 0) {
      setData(roles.map(role => {
        return {
          ...role,
          role: roleTypes.find(type => type.value === role.role).label,
          action: actionTypes.find(type => type.value === role.action).label,
          companyName: getCompanyName(role.resource)
        }
      }));
    }
  };
  useEffect(onDataChange, [roles, companies]);

  const onSortModelChange = () => {
    dispatch(userActions.userRolesCountFetchRequest(userId));
    dispatch(userActions.userRolesFetchRequest(userId, {sort: sort, order: order, page, rowsPerPage}));
  }
  useEffect(onSortModelChange, [sort, order]);

  const handleChangePage = (e) => {
    setPage(e);
    dispatch(userActions.userRolesFetchRequest(userId, e, rowsPerPage));
  };

  const handleChangePageSize = (e) => {
    setRowsPerPage(e); // TODO: not confirmed
    dispatch(userActions.userRolesFetchRequest(userId, page, e));
  };

  // Updates the state w/ the new selection
  // TODO: This might not be used anymore (since we moved to the grid.apiRef)
  const handleSelectionModelChange = (e) => {
    setSelected(e);
  }

  const handleSortModelChange = (newModel) => {
    if ( newModel && Array.isArray(newModel) && newModel.length > 0 ) {
      setSort(newModel[0]['field']);
      setOrder(newModel[0]['sort'] === 'asc' ? 1 : 0);
    }
  };


  return (
    <DataGrid
      loading={loading.roles}
      autoHeight
      disableColumnMenu
      paginationMode="server"
      filterMode="server"
      sortingMode="server"
      sortModel={[ { field: sort, sort: order ? 'asc' : 'desc' } ]}
      onSortModelChange={handleSortModelChange}
      components={{ Toolbar: UserRolesToolbar }}
      componentsProps={{ toolbar: { userId: userId, companies: companies } }}
      checkboxSelection={true}
      columns={headers}
      rows={data}
      rowCount={total ?? 0}
      pageSize={rowsPerPage}
      rowsPerPageOptions={[rowsPerPage]}
      onSelectionModelChange={handleSelectionModelChange}
      onPageChange={handleChangePage}
      onPageSizeChange={handleChangePageSize}
    />
  )
}

export default UserRoles
