import { CONTACT_REQUESTS_CREATE } from '../constants';

const init = {};

export function contactRequests(state = init, action) {
  switch (action.type) {
    case CONTACT_REQUESTS_CREATE.SUCCESS:
      return {
        ...state,
        ...action.request,
      };
    case CONTACT_REQUESTS_CREATE.FAILURE:
    case CONTACT_REQUESTS_CREATE.REQUEST:
    default:
      return state;
  }
}
