import { all } from 'redux-saga/effects'
import { companiesSagas } from './companies.actions';
import { companySagas } from './company.actions';
import { contactRequestsSagas } from './contact-requests.actions';
import { authenticationSagas } from './authentication.actions';
import { helpdeskSagas } from './helpdesk.actions';
import { hotspotSagas } from './hotspot.actions';
import { usersSagas } from './users.actions';
import { userSagas } from './user.actions';
import { zabbixSagas } from './zabbix.actions';

export * from './authentication.actions';
export * from './alerts.actions';
export * from './company.actions';
export * from './contact-requests.actions';
export * from './helpdesk.actions';
export * from './hotspot.actions';
export * from './companies.actions';
export * from './users.actions';
export * from './user.actions';
export * from './zabbix.actions';

// Need to some how combine all of the saga's from each actions module into a
// single root saga, for import into the reducers and so we can run them...
export function *Sagas() {
  yield all([
    ...authenticationSagas,
    ...companiesSagas,
    ...companySagas,
    ...contactRequestsSagas,
    ...helpdeskSagas,
    ...hotspotSagas,
    ...usersSagas,
    ...userSagas,
    ...zabbixSagas,
  ]);
}
