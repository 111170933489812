// webapi for zabbix data
var Promise = require('bluebird');
var config = require('../../config');

module.exports = {
  hosts: hosts,
  wanInterface: wanInterface,
  item: item,
  history: history,
  history24: history24,
  bandwidthUp24: bandwidthUp24,
  bandwidthDown24: bandwidthDown24,
  uniqueDevices24: uniqueDevices24
};

function errorArrayToString(errors){
  return errors.join('; ');
}

// returns 24 hours ago from now unix timestamp
function twentyFourHoursAgo(){
  return ((new Date().getTime()/1000) >> 0) - (24*60*60);
}

function bandwidthUp24(uid, host, iface){
  return history24(uid, host, 'net.if.out['+iface+']', 3);
}

function bandwidthDown24(uid, host, iface){
  return history24(uid, host, 'net.if.in['+iface+']', 3);
}

function uniqueDevices24(uid, host){
  return history24(uid, host, 'dhcp.leases', 3);
}

function history24(uid, host, key, valueType){
  return history(uid, host, key, twentyFourHoursAgo(), valueType);
}

function wanInterface(uid, host){
  return item(uid, host, 'uci.get[network.wan.ifname]');
}

function hosts(uid){
  return new Promise(function(resolve, reject){
    const url = config.default.api.address + '/companies/' + uid + '/zabbix/hosts';
    fetch(url, {
      method: 'GET',
      dataType: 'json'
    })
    .then(response => {
      return response.json()
    })
    .then( data => {
      if(data.success){
        return resolve(data.hosts);
      } else {
        return reject(new Error(data.errors.join('; ')));
      }
    })
    .catch(error => {
      reject(new Error(error));
    });
  });
}

function item(uid, host, key){
  return new Promise(function(resolve, reject){
    const url = config.default.api.address + '/companies/' + uid + '/zabbix/current?host='+encodeURIComponent(host)+'&key='+encodeURIComponent(key);
    fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Context-Type': 'text/plain',
      },
    })
    .then(response => {
      return response.json();
    })
    .then(result => {
        if(result.success){
          return resolve(result);
        }
        return reject(result.errors);
      })
    .catch(error => {
      reject(new Error(error));
    });
  });
}

function history(uid, host, key, startTimestamp, valueType){
  return new Promise(function(resolve, reject){
    const url = config.default.api.address + '/companies/' + uid + '/zabbix/history?host='
      + encodeURIComponent(host) + '&key=' + encodeURIComponent(key) + '&startTimestamp='+encodeURIComponent(startTimestamp)+'&valueType='+encodeURIComponent(valueType);
    fetch( url, {
      method: 'GET',
      headers: {
        'Context-Type': 'application/json',
      },
    })
    .then( response => {
      return response.json();
    })
    .then( data => {
      if(data.success){
        return resolve(data);
      }
      return reject(new Error(errorArrayToString(data.errors)));
    })
    .catch(error => {
      reject(new Error(error));
    });
  });
}
