import React from 'react';

import { styled } from '@mui/material/styles';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import PrintIcon from '@mui/icons-material/Print';

const Number = styled(Typography)( ({theme}) => ({
  display: 'flex',
  alignItems: 'center',
}));

const TelephoneNumber = ({name, email, phone1, phone2, fax }) => {
  return (
    <Stack spacing={0}>
      {name && <Number variant="h6">{name}</Number> }
      {email && <Number variant="body1"><EmailIcon sx={{marginRight: '4px'}}/>{email}</Number> }
      {phone1 && <Number variant="body1"><PhoneIcon sx={{marginRight: '4px'}}/>{phone1}</Number> }
      {phone2 && <Number variant="body1"><PhoneIcon sx={{marginRight: '4px'}}/>{phone2}</Number> }
      {fax && <Number variant="body1"><PrintIcon sx={{marginRight: '4px'}}/>{fax}</Number> }
    </Stack>
  );
}

export default TelephoneNumber;
