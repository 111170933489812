import { call, put, throttle, takeEvery } from 'redux-saga/effects';
import { createAction } from '../../helpers/actions'
import { serviceCall } from '../../helpers/effects';

import {
  TICKETS_FETCH,
  TICKETS_COUNT_FETCH,
} from '../constants';
import { helpdeskServices } from '../services';

import { alertActions } from './';


export const helpdeskActions = {
  ticketsFetchRequest: (companyId, options) => createAction(TICKETS_FETCH.REQUEST, {
    companyId: companyId,
    options: options,
  }),
  ticketsFetchSuccess: (tickets) => createAction(TICKETS_FETCH.SUCCESS, { tickets: tickets }),
  ticketsFetchFailure: (errorMessage) => createAction(TICKETS_FETCH.FAILURE, { errorMessage: errorMessage }),

  ticketsCountFetchRequest: (companyId, options) => createAction(TICKETS_COUNT_FETCH.REQUEST,
    { companyId: companyId, options: options}),
  ticketsCountFetchSuccess: (total) => createAction(TICKETS_COUNT_FETCH.SUCCESS, { total: total }),
  ticketsCountFetchFailure: (errorMessage) => createAction(TICKETS_COUNT_FETCH.FAILURE, { errorMessage: errorMessage })
}

export function* fetchTickets(action) {
  try {
    const tickets = yield serviceCall(
      helpdeskServices.fetchTickets,
      action.companyId,
      action.options,
    );
    yield put(helpdeskActions.ticketsFetchSuccess(tickets));
  } catch (e) {
    yield put(helpdeskActions.ticketsFetchFailure(e.message));
    yield put(alertActions.error(`Failed to fetch tickets: ${e.message}`, action.type));
  }
}

export function* fetchTicketsCount(action) {
  try {
    //yield delay(1000);
    const total = yield serviceCall(
      helpdeskServices.fetchTicketsCount,
      action.companyId,
      action.options
    );
    yield put(helpdeskActions.ticketsCountFetchSuccess(total));
  } catch (e) {
    yield put(helpdeskActions.ticketsCountFetchFailure(e.message));
    yield put(alertActions.error(`Failed to fetch ticket count: ${e.message}`, action.type));
  }
}
export const helpdeskSagas = [
  //takeEvery(TICKETS_FETCH.REQUEST, fetchTickets)
  throttle(1000, TICKETS_FETCH.REQUEST, fetchTickets),
  throttle(1000, TICKETS_COUNT_FETCH.REQUEST, fetchTicketsCount)
]


