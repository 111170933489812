// ActionHelpers
const asyncTypes = {
  REQUEST: 'REQUEST',
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE'
}

export const createAsyncConstants = typeString =>
  Object.values(asyncTypes).reduce((acc, current) => {
    acc[current] = `${typeString}_${current}`
    return acc
  }, {});

export const createAction = (type, payload = {}) => ({ type, ...payload });

