import { call, put, race, delay, takeEvery } from 'redux-saga/effects';
import { createAction } from '../../helpers/actions'
import { serviceCall } from '../../helpers/effects';

import {
  USER_FETCH,
  USER_UPDATE,
  USER_CREATE,
  USER_REMOVE,
  USER_ROLES_FETCH,
  USER_ROLES_COUNT_FETCH,
  USER_ROLES_ADD,
  USER_ROLES_REMOVE,
  USER_CLEAR_ACTIVE
} from '../constants';

import { userServices } from '../services';

import { alertActions } from './';

export const userActions = {
  userFetchRequest: (userId) => createAction(USER_FETCH.REQUEST, { userId: userId }),
  userFetchSuccess: (user) => createAction(USER_FETCH.SUCCESS, { user: user }),
  userFetchFailure: (errorMessage) => createAction(USER_FETCH.FAILURE, { errorMessage: errorMessage }),

  userUpdateRequest: (user) => createAction(USER_UPDATE.REQUEST, { user: user }),
  userUpdateSuccess: (user) => createAction(USER_UPDATE.SUCCESS, { user: user }),
  userUpdateFailure: (errorMessage) => createAction(USER_UPDATE.FAILURE, { errorMessage: errorMessage }),

  userCreateRequest: (user) => createAction(USER_CREATE.REQUEST, { user: user }),
  userCreateSuccess: (user) => createAction(USER_CREATE.SUCCESS, { user: user }),
  userCreateFailure: (errorMessage) => createAction(USER_CREATE.FAILURE, { errorMessage: errorMessage }),

  userRemoveRequest: (userId) => createAction(USER_REMOVE.REQUEST, { userId: userId }),
  userRemoveSuccess: (user) => createAction(USER_REMOVE.SUCCESS, { user: user }),
  userRemoveFailure: (errorMessage) => createAction(USER_REMOVE.FAILURE, { errorMessage: errorMessage }),

  userRolesFetchRequest: (userId, options) => createAction(USER_ROLES_FETCH.REQUEST, { userId: userId, options}),
  userRolesFetchSuccess: (roles) => createAction(USER_ROLES_FETCH.SUCCESS, { roles: roles }),
  userRolesFetchFailure: (errorMessage) => createAction(USER_ROLES_FETCH.FAILURE, { errorMessage: errorMessage }),

  userRolesCountFetchRequest: (userId) => createAction(USER_ROLES_COUNT_FETCH.REQUEST, { userId: userId }),
  userRolesCountFetchSuccess: (total) => createAction(USER_ROLES_COUNT_FETCH.SUCCESS, { total: total }),
  userRolesCountFetchFailure: (errorMessage) => createAction(USER_ROLES_COUNT_FETCH.FAILURE, { errorMessage: errorMessage }),

  userRolesAddRequest: (role) => createAction(USER_ROLES_ADD.REQUEST, { role: role }),
  userRolesAddSuccess: (role) => createAction(USER_ROLES_ADD.SUCCESS, { role: role }),
  userRolesAddFailure: (errorMessage) => createAction(USER_ROLES_ADD.FAILURE, { errorMessage: errorMessage }),

  userRolesRemoveRequest: (roleId) => createAction(USER_ROLES_REMOVE.REQUEST, { roleId: roleId }),
  userRolesRemoveSuccess: (role) => createAction(USER_ROLES_REMOVE.SUCCESS, { role: role }),
  userRolesRemoveFailure: (errorMessage) => createAction(USER_ROLES_REMOVE.FAILURE, { errorMessage: errorMessage }),

  userClearActive: (user) => createAction(USER_CLEAR_ACTIVE, { user: user }),
}

export function* fetchUser(action) {
  try {
    const user = yield serviceCall(userServices.fetchUser, action.userId);
    yield put(userActions.userFetchSuccess(user));
  } catch (e) {
    yield put(userActions.userFetchFailure(e.message));
    yield put(alertActions.error(`Failed to fetch user: ${e.message}`, action.type));
  }
}

export function* updateUser(action) {
  try {
    const user = yield serviceCall(userServices.updateUser, action.user);
    yield put(userActions.userUpdateSuccess(user));
  } catch (e) {
    yield put(userActions.userUpdateFailure(e.message));
    yield put(alertActions.error(`Failed to update user: ${e.message}`, action.type));
  }
}

export function* createUser(action) {
  try {
    const user = yield serviceCall(userServices.createUser, action.user);
    yield put(userActions.userCreateSuccess(user));
  } catch (e) {
    yield put(userActions.userCreateFailure(e.message));
    yield put(alertActions.error(`Failed to create user: ${e.message}`, action.type));
  }
}

export function* removeUser(action) {
  try {
    const user = yield serviceCall(userServices.removeUser, action.userId);
    yield put(userActions.userRemoveSuccess(user));
  } catch (e) {
    yield put(userActions.userRemoveFailure(e.message));
    yield put(alertActions.error(`Failed to remove user: ${e.message}`, action.type));
  }
}


export function* fetchUserRoles(action) {
  try {
    const roles = yield serviceCall(userServices.fetchUserRoles, action.userId, action.options);
    yield put(userActions.userRolesFetchSuccess(roles));
  } catch (e) {
    yield put(userActions.userRolesFetchFailure(e.message));
    yield put(alertActions.error(`Failed to fetch user roles: ${e.message}`, action.type));
  }
}

export function* fetchUserRolesCount(action) {
  try {
    const total = yield serviceCall(userServices.fetchUserRolesCount, action.userId);
    yield put(userActions.userRolesCountFetchSuccess(total));
  } catch (e) {
    yield put(userActions.userRolesCountFetchFailure(e.message));
    yield put(alertActions.error(`Failed to fetch user roles count: ${e.message}`, action.type));
  }
}

export function* addUserRoles(action) {
  try {
    const role = yield serviceCall(userServices.addUserRoles, action.role);
    yield put(userActions.userRolesAddSuccess(role));
    yield put(userActions.userRolesFetchRequest(role.userId, {sort: 'companyId', order: 1, page: 0, size: 10}));
  } catch (e) {
    yield put(userActions.userRolesAddFailure(e.message));
    yield put(alertActions.error(`Failed to add user role: ${e.message}`, action.type));
  }
}

export function* removeUserRoles(action) {
  try {
    const role = yield serviceCall(userServices.removeUserRoles, action.roleId);
    yield put(userActions.userRolesRemoveSuccess(role));
    //yield call(userActions.userRolesFetchRequest,role.userId, 0, 10);
    yield put(userActions.userRolesFetchRequest(role.userId, {sort: 'companyId', order: 1, page: 0, size: 10}));
  } catch (e) {
    yield put(userActions.userRolesRemoveFailure(e.message));
    yield put(alertActions.error(`Failed to remove user role: ${e.message}`, action.type));
  }
}

export const userSagas = [
  takeEvery(USER_FETCH.REQUEST, fetchUser),
  takeEvery(USER_UPDATE.REQUEST, updateUser),
  takeEvery(USER_CREATE.REQUEST, createUser),
  takeEvery(USER_REMOVE.REQUEST, removeUser),
  takeEvery(USER_ROLES_FETCH.REQUEST, fetchUserRoles),
  takeEvery(USER_ROLES_COUNT_FETCH.REQUEST, fetchUserRolesCount),
  takeEvery(USER_ROLES_ADD.REQUEST, addUserRoles),
  takeEvery(USER_ROLES_REMOVE.REQUEST, removeUserRoles),
]

