import React from 'react';
import PropTypes from 'prop-types';

class Divider extends React.Component{
  render(){
    var that = this;

    function getClass(){
      var className = 'react-Divider';
      if(that.props.half){
        className += ' half';
      }
      return className;
    }

    return (
      <div
        className={getClass()}
      ></div>
    );
  }
}

Divider.propTypes = {
  half: PropTypes.bool
}

export default Divider;
