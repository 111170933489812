export const accessCodeRotation = {
  0: 'NONE',
  1: 'NONE',
  2: 'NONE',
  3: 'WEEKLY',
  4: 'WEEKLY',
  5: 'NONE',
  6: 'NONE',
  7: 'MONTHLY',
  8: 'DAILY',
  9: 'NONE',
  10: 'NONE',
  11: 'NONE',
  12: 'DAILY',
  13: 'QUARTERLY',
  14: 'WEEKLY',
  15: 'MONTHLY',
  16: 'QUARTERLY'
}
