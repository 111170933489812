import React from 'react';

// Date Functions
import {
  format,
  getUnixTime,
  fromUnixTime,
  isBefore,
  getHours,
  setHours,
  startOfHour,
  startOfTomorrow,
  addHours,
  isFriday,
  nextFriday,
  startOfMonth,
  addMonths,
  isSameDay,
  startOfQuarter,
  addQuarters,
} from 'date-fns';

import { styled } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import InputChip from '../../components/InputChip';

import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import FaceIcon from '@mui/icons-material/Face';
import AddIcon from '@mui/icons-material/Add';

import CountDown from '../../components/CountDown';
import { accessCodeRotation } from '../../redux/constants';

import AccessCodeLink from './AccessCodeLink';

const Highlight = styled(Typography)( ({theme}) => ({
  backgroundColor: "#f0f0f0",
  borderLeftStyle: 'solid',
  borderColor: '#556cd6',
  padding: '8px',
}));


const AccessCodeDetail = ({
  companyId = 0,
  editable = false,
  accessCode,
  accessCodeType,
  emailList = [],
  handleChange = null,
}) => {
  const currentRotation = calculateAccessCodeRotation();
  const nextScheduled = currentRotation.next(accessCodeType);

  return (
    <Box style={{padding:'15px'}}>
      <Grid container spacing={3}>
        <Grid item sx={{ display: { xs: "none", sm: "block" } }} sm={12}>
          <Typography variant="h6" color="secondary">Schedule</Typography>
          {nextScheduled ?
            <CountDown
              key='access-code-countdown'
              timestamp={nextScheduled}
            />
          : ''
          }
        </Grid>
        <Grid item sx={{ display: { xs: "block", sm: "none" } }} xs={12}>
          <Typography variant="h6" color="secondary">Schedule</Typography>
          Your access code will rotate on &nbsp;
          {nextScheduled ? format(fromUnixTime(nextScheduled), 'MMM do yyyy, h:mm:ss a') : 'None'}
        </Grid>
        <Grid item xs={6}>
          <Highlight variant="body1">
            Your access code change schedule is: <Button>{accessCodeRotation[accessCodeType]}</Button>
          </Highlight>
        </Grid>
        <Grid item xs={6}>
          <Highlight variant="body1">
            View your access code handout
            { accessCode ?
                <AccessCodeLink accessCode={accessCode} companyId={companyId} />:
                <Button>Not Available</Button>
            }
          </Highlight>
        </Grid>
        <Grid item sm={12}>
          <Typography variant="h6" color="secondary" id="notify-list-subheader">Notify List</Typography>
          <Stack spacing={1}>
            <Highlight variant="body2">When your access code changes, emails and faxes are sent with the new access code. You can specify who will get these emails and faxes below.</Highlight>
            <Stack direction="row" spacing={0} sx={{flexWrap: 'wrap'}} >
              <InputChip disabled={!editable} values={emailList} onChange={handleChange}/>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

/*
  <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
    <AddIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
    <TextField id="input-with-sx" label="Email Address" variant="outlined" />
  </Box>
  {emailList.split(',').map((address, i) => (
    <Chip icon={<FaceIcon />} key={i} label={address} variant="outlined" sx={{ marginRight: 1, marginBottom: 1 }} />
  ))}
*/


function calculateAccessCodeRotation(){
  var SCHEDULES = {
    DAILY: 'DAILY',
    WEEKLY: 'WEEKLY',
    MONTHLY: 'MONTHLY',
    QUARTERLY: 'QUARTERLY',
    NONE: 'NONE' // non-rotating
  };
  // dp company_rad_type to schedules
  var TYPES = {
    0: SCHEDULES.NONE, // none
    1: SCHEDULES.NONE, // Standard Terms and Conditions
    2: SCHEDULES.NONE, // Enhanced Terms and Conditions
    3: SCHEDULES.WEEKLY, // Weekly Rotation Standard Terms and Conditions
    4: SCHEDULES.WEEKLY, // Weekly Rotation Enhanced Terms and Conditions
    5: SCHEDULES.NONE, // Radius Pass Thru - Hampton
    6: SCHEDULES.NONE, // Non-Rotating Password Terms and Conditions
    7: SCHEDULES.MONTHLY, // Monthly Rotation Terms and Conditions
    8: SCHEDULES.DAILY, // Daily Rotation Terms and Conditions
    9: SCHEDULES.NONE, // No ads Terms and Conditions
    10: SCHEDULES.NONE, // No ads Non-Rotating Password Terms and Conditions
    11: SCHEDULES.NONE, // NO ACCESS - SERVICE DISABLED
    12: SCHEDULES.DAILY, // No ads Daily Rotation Terms and Conditions
    13: SCHEDULES.QUARTERLY, // Quarterly Rotation Terms and Conditions
    14: SCHEDULES.WEEKLY, // No ads Weekly Rotation Terms and Conditions
    15: SCHEDULES.MONTHLY, // No ads Monthly Rotation Terms and Conditions
    16: SCHEDULES.QUARTERLY // No ads Quarterly Rotation Terms and Conditions
  };

  // DAILY: next noon after t
  const nextDailyRotation = (t) => {
    if (getHours(t) < 12) {
      return startOfHour(setHours(t, 12));
    }
    return addHours(startOfTomorrow(t), 12);
  }

  // WEEKLY: next Friday at noon after from t
  const nextWeeklyRotation = (t) => {
    if (isFriday(t) && getHours(t) < 12) {
      return startOfHour(setHours(t, 12));
    }
    return startOfHour(setHours(nextFriday(t), 12));
  }

  // MONTHLY: next first Friday of month at noon after t
  const nextMonthlyRotation = (t) => {
    const m = setHours(nextFriday(startOfMonth(t)), 12);
    if ( isBefore(t, m) ) {
      return m;
    }
    return setHours(nextFriday(startOfMonth(addMonths(t, 1))), 12)
  }

  // QUARTERLY: next first day of quarter at noon after from
  // quarter days are 1-1, 4-1, 7-1, 10-1
  const nextQuarterlyRotation = (t) => {
    if ( isSameDay(t, startOfQuarter(t)) && getHours(t) < 12 ) {
      return startOfHour(setHours(startOfQuarter(t), 12))
    }
    return startOfHour(setHours(startOfQuarter(addQuarters(t, 1)), 12))
  }

 /**
  * Gets the unixtimestamp of next rotation
  * @param {string} schedule Schedule value from SCHEDULES (e.g. SCHEDULES.DAILY)
  * @param {Date} [from] Date to calculate the next rotation from
  * @return {unixtimestamp|boolean} Next rotatio/n or false if schedule is invalid
  */
  function next(schedule, from){
    // calculate from from or if not supplied, from now
    var timestamp = from ? new Date(from) : new Date();
    var nextRotation = false;
    switch(TYPES[schedule]){
      case SCHEDULES.DAILY:
        nextRotation = getUnixTime(nextDailyRotation(timestamp));
        break;
      case SCHEDULES.WEEKLY:
        nextRotation = getUnixTime(nextWeeklyRotation(timestamp));
        break;
      case SCHEDULES.MONTHLY:
        nextRotation = getUnixTime(nextMonthlyRotation(timestamp));
        break;
      case SCHEDULES.QUARTERLY:
        nextRotation = getUnixTime(nextQuarterlyRotation(timestamp));
        break;
      default:
        nextRotation = false;
        break;
    }
    return nextRotation;
  }

  return {
    SCHEDULES: SCHEDULES,
    TYPES: TYPES,
    next: next
  };
}

export default AccessCodeDetail;
