import React, { useState, useEffect } from 'react';

import makeStyles from '@mui/styles/makeStyles';

import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Popover from '@mui/material/Popover';

import DateRangeIcon from '@mui/icons-material/DateRange';

import {
  addDays,
  startOfWeek,
  endOfWeek,
  addWeeks,
  startOfMonth,
  endOfMonth,
  addMonths,
} from 'date-fns';

import { DateRangePicker, DateRange } from "materialui-daterange-picker";

const useStyles = makeStyles((theme) => ({
  root: {},
}));


const getDefaultRanges = (date) => [
  {
    label: 'All',
    startDate: null,
    endDate: null,
  },
  {
    label: 'Today',
    startDate: date,
    endDate: date,
  },
  {
    label: 'This Month',
    startDate: startOfMonth(date),
    endDate: date,
  },
  {
    label: 'Last Month',
    startDate: startOfMonth(addMonths(date, -1)),
    endDate: endOfMonth(addMonths(date, -1)),
  },
  {
    label: 'Last 7 Days',
    startDate: addWeeks(date, -1),
    endDate: date,
  },
  {
    label: 'Last 30 Days',
    startDate: addDays(date, -30),
    endDate: date,
  },
  {
    label: 'Last 60 Days',
    startDate: addDays(date, -60),
    endDate: date,
  },
];

const DatePickerButton = (props) => {
  const classes = useStyles();

  const [dateRange, setDateRange] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [suggestedRanges, setSuggestedRanges] = useState(getDefaultRanges(new Date()));

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onRangeChange = (range) => {
    if (!range.endDate || !range.startDate) {
      setDateRange(null);
      props.onChange({ range: null });
      return;
    }

    const diff = range?.endDate - range?.startDate;
    const days = Math.round(diff / (1000 * 60 * 60 * 24));

    range.diffDays = days;

    setDateRange(range);
    props.onChange({ range: range });
  };

  const open = Boolean(anchorEl);
  const toggle = () => setAnchorEl(!open);

  return <>
    <Tooltip title="Date Range">
      <IconButton aria-label="date list" onClick={handleClick} size="large">
        <Badge badgeContent={dateRange?.diffDays ?? null} color="secondary">
          <DateRangeIcon />
        </Badge>
      </IconButton>
    </Tooltip>
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <DateRangePicker
        open={open}
        toggle={toggle}
        onChange={onRangeChange}
        definedRanges={suggestedRanges}
      />
    </Popover>
  </>;
};

export default DatePickerButton;
