import {
  USER_FETCH,
  USER_UPDATE,
  USER_CREATE,
  USER_REMOVE,
  USER_ROLES_FETCH,
  USER_ROLES_COUNT_FETCH,
  USER_CLEAR_ACTIVE
} from '../constants';

const init = {
  current: {},
  roles: {
    data: [],
    total: 0
  }
}
export function user(state = init, action) {
  switch (action.type) {
    case USER_FETCH.SUCCESS:
        return {
        ...state,
        current: action.user,
      };
      break;
    case USER_UPDATE.SUCCESS:
      return {
        ...state,
        current: action.user,
      };
      break;
    case USER_CREATE.SUCCESS:
      return {
        ...state,
        current: action.user,
      };
      break;
    case USER_REMOVE.SUCCESS:
      return {
        ...state,
        current: {},
      };
      break;
    case USER_ROLES_FETCH.SUCCESS:
      return {
        ...state,
        roles: {
          ...state.roles,
          data: action.roles
        }
      };
      break;
    case USER_ROLES_COUNT_FETCH.SUCCESS:
      return {
        ...state,
        roles: {
          ...state.roles,
          total: action.total
        }
      };
      break;
    case USER_CLEAR_ACTIVE:
      return init;
      break;
    default:
      return state;
  }
}
