import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

// Icons
import AccountCircle from '@mui/icons-material/AccountCircle';

import TelephoneNumber from '../TelephoneNumber';
import ContactMe from '../ContactMe';
import ErrorBoundry from '../../components/ErrorBoundry';

import {
  createErrorMessageSelector,
  createLoadingSelector
} from '../../helpers/selectors';

import { contactRequestsActions } from '../../redux/actions';


function AMInfo ({
  companyId = 0,
  accountManager = 'unassigned',
  contactEmail = '',
  contactPhone = '',
}) {
  const dispatch = useDispatch();

  const requestType = "contactAM";

  // Get Contact Request Status from global State
  const contactRequest = useSelector(state => state?.contactRequest);
  const loading = {
    request: useSelector(createLoadingSelector(['CONTACT_REQUESTS_CREATE'])),
  }
  const errorMessage = {
    request: useSelector(createErrorMessageSelector(['CONTACT_REQUESTS_CREATE'])),
  }
  const handleSendClick = (contactType, message) => {
    dispatch(contactRequestsActions.contactRequestsCreateRequest(companyId, requestType, contactType, message));
  }

  return (
    <Box style={{padding:'15px'}}>
      <ErrorBoundry errorMessage={errorMessage.request}>
        <Grid container spacing={2} direction="row" justifyContent="space-evenly" alignItems="center">
          <Grid item>
            <AccountCircle sx={{ fontSize: 80 }} />
          </Grid>
          <Grid item>
            <TelephoneNumber name={accountManager} email={contactEmail} phone1={contactPhone} />
          </Grid>
          <Grid item xs={12} sm={12} fixed='true'>
            <Grid container justifyContent = "center">
              <ContactMe disabled={loading.request} onSend={handleSendClick}/>
            </Grid>
          </Grid>
        </Grid>
      </ErrorBoundry>
    </Box>
  );
}

export default AMInfo;
