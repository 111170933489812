import { createAsyncConstants } from '../../helpers/actions.js';

//export const COMPANIES_FETCH_ASYNC = createAsyncConstants('COMPANIES_FETCH');
export const AUTHENTICATION_LOGIN = createAsyncConstants('AUTHENTICATION_LOGIN');
export const AUTHENTICATION_LOGOUT = createAsyncConstants('AUTHENTICATION_LOGOUT');
export const AUTHENTICATION_REAUTH = createAsyncConstants('AUTHENTICATION_REAUTH');
export const AUTHENTICATION_VERIFY_EMAIL = createAsyncConstants('AUTHENTICATION_VERIFY_EMAIL');
export const AUTHENTICATION_VERIFY_SIGNUP = createAsyncConstants('AUTHENTICATION_VERIFY_SIGNUP');
export const AUTHENTICATION_RESEND_VERIFY_SIGNUP = createAsyncConstants('AUTHENTICATION_RESEND_VERIFY_SIGNUP');
export const AUTHENTICATION_SEND_RESET_EMAIL = createAsyncConstants('AUTHENTICATION_SEND_RESET_EMAIL');
export const AUTHENTICATION_RESET_PASSWORD = createAsyncConstants('AUTHENTICATION_RESET_PASSWORD');
export const AUTHENTICATION_RESET_PASSWORD_TOKEN = createAsyncConstants('AUTHENTICATION_RESET_PASSWORD_TOKEN');


export const authenticationCostants = {
  AUTHENTICATION_LOGIN,
  AUTHENTICATION_LOGOUT,
  AUTHENTICATION_REAUTH,
  AUTHENTICATION_VERIFY_EMAIL,
  AUTHENTICATION_VERIFY_SIGNUP,
  AUTHENTICATION_RESEND_VERIFY_SIGNUP,
  AUTHENTICATION_SEND_RESET_EMAIL,
  AUTHENTICATION_RESET_PASSWORD,
  AUTHENTICATION_RESET_PASSWORD_TOKEN,
}

export default authenticationCostants;
