import {
  USERS_FETCH,
  USERS_COUNT_FETCH,
} from '../constants/users.constants';

const init = {
  users: [],
  total: 0
}
export const users = (state = init, action) => {
  switch (action.type) {
    case USERS_FETCH.SUCCESS:
      return {
        ...state,
        users: action.users
      };
    case USERS_COUNT_FETCH.SUCCESS:
      return {
        ...state,
        total: action.total
      };
    default:
      return state
  }
};
