import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { compose } from 'redux';
import { useHistory, Redirect, useParams } from 'react-router-dom';

// Material UI
import { styled } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';


import ContactMe from '../../components/ContactMe';

// Ovation
import { authenticationActions } from '../../redux/actions';
import { contactRequestsActions } from '../../redux/actions';

import {
  createErrorMessageSelector,
  createLoadingSelector
} from '../../helpers/selectors';


const useStyles = makeStyles((theme) => ({
  root: {}
}));


const LoginFormBox = styled(Paper)( ({theme}) => ({
  padding: '4px',
  margin: '24px',
  boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
  backgroundColor: "#f0f0f0",
  "&.hover": {
    boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)"
  },
  "& .FormTitle": {
    marginLeft: '12px',
  },
  "& .MuiTextField-root": {
    marginLeft: '12px',
    marginRight: '12px',
  },
  "& .UserActionButtons": {
    textAlign: 'right',
    "& .MuiButtonBase-root": {
      marginLeft: '12px',
      marginRight: '12px',
    }
  },
  "& .LoginInfo": {
    padding: '24px',
  }
}) );


const ContactInfoBox = styled(Paper)( ({theme}) => ({
  padding: '4px',
  margin: '24px',
  boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
  backgroundColor: '#f0f0f0',
}) )

const PrivacyInfoBox = styled(Paper)( ({theme}) => ({
  overflow: 'scroll',
  display: 'block',
  position: 'absolute',
  top: '10%',
  left: '10%',
}) );

const PrivacyInfoText = styled(Paper)( ({theme}) => ({
  margin: '48px',
  padding: '4px',
  width: '80%',
}));


const Login = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();

  const [ username, setUsername ] = useState('');
  const [ password, setPassword ] = useState('');
  const [ submitted, setSubmitted ] = useState(false);
  const [ privacyOpen, setPrivacyOpen ] = useState(false);
  const [ resetPasswordOpen, setResetPasswordOpen ] = useState(false);
  const [ resetPasswordAddress, setResetPasswordAddress ] = useState('');
  const [ requestAccountAddress, setRequestAccountAddress ] = useState('');
  const [ requestAccount, setRequestAccount ] = useState({
    requestAccountAddress: '',
    requestAccountName: '',
    requestAccountProperty: '',
  });
  const [ requestAccountProperty, setRequestAccountProperty ] = useState('');
  const [ requestAccountOpen, setRequestAccountOpen ] = useState(false);

  const loggedIn = useSelector(state => state?.authentication?.loggedIn);

  const loading =  {
    authentication: useSelector(createLoadingSelector(['AUTHENTICATION_LOGIN'])),
    resetPassword: useSelector(createLoadingSelector(['AUTHENTICATION_SEND_RESET_EMAIL'])),
    requestAccount: useSelector(createLoadingSelector(['CONTACT_REQUESTS_CREATE']))
  };

  const errorMessage = {
    authentication: useSelector(createErrorMessageSelector(['AUTHENTICATION_LOGIN'])),
    resetPassword: useSelector(createErrorMessageSelector(['AUTHENTICATION_SEND_RESET_EMAIL'])),
    requestAccount: useSelector(createErrorMessageSelector(['CONTACT_REQUESTS_CREATE']))
  };

  const onFirstLoad = () => {
    if (loggedIn === true){
      history.push('/');
    }
  };
  useEffect(onFirstLoad, []);

  const onLoggedIn = () => {
    if (loggedIn === true){
      history.push('/');
    }
  };
  useEffect(onLoggedIn, [loggedIn]);

  const updateState = () => {
    setUsername(username);
    setPassword(password);
    setResetPasswordAddress(resetPasswordAddress);
  }
  useEffect(updateState, [username, password, resetPasswordAddress])

  // TODO: Do we care about errors here? We are using the alert system to
  // display the errors below. but perhaps we still would like to highlight or
  // display some visual indicator that an error occured.
  /*
  const onErrorMessage = () => {
    console.log("error: ", errorMessage.authentication);
  };
  useEffect(onErrorMessage, [errorMessage.authentication]);

  const onErrorMessagePasswordReset = () => {
    console.log("error: ", errorMessage.resetPassword);
  };
  useEffect(onErrorMessagePasswordReset, [errorMessage.resetPassword]);
  */

  const onLoading = () => {
    if (loading.resetPassword === false && errorMessage.resetPassword === "") {
      // no errors found and reset worked
      handleResetPasswordClose();
    }
    if (loading.requestAccount === false && errorMessage.requestAccount === "") {
      // no errors found and reset worked
      handleRequestAccountClose();
    }
  };
  useEffect(onLoading, [loading.resetPassword, loading.requestAccount]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitted(true);

    if (username && password) {
      dispatch(authenticationActions.loginRequest(username, password))
    }
  }

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };
  const handleResetPasswordChange = (event) => {
    setResetPasswordAddress(event.target.value);
  };

  const handleResetPasswordOpen = (event) => {
    setResetPasswordOpen(true)
    setResetPasswordAddress(username);
  };

  const handleResetPasswordClose = (event) => {
    setResetPasswordOpen(false)
  };

  const handleResetPassword = (event) => {
    dispatch(authenticationActions.sendResetEmailRequest(resetPasswordAddress))
  };

  const handlePrivacyOpen = (event) => {
    event.preventDefault();
    setPrivacyOpen(true);
  };

  const handlePrivacyClose = (event) => {
    setPrivacyOpen(false);
  };

  const handleRequestAccount = (event) => {
    console.log("sending request account:", requestAccount);
    const message = `${requestAccount.requestAccountName} (email: ${requestAccount.requestAccountAddress}) with property: ${requestAccount.requestAccountProperty}`
    dispatch(contactRequestsActions.contactRequestsCreateRequest(null, "requestAccount", 'email', message));
  };
  const handleRequestAccountChange = (event) => {
    setRequestAccountAddress(event.target.value);
    setRequestAccount({ ...requestAccount, [event.target.id]: event.target.value });
  };

  const handleRequestAccountOpen = (event) => {
    setRequestAccountOpen(true)
  };

  const handleRequestAccountClose = (event) => {
    setRequestAccountOpen(false)
  };

  return (
    <Box>
      <LoginFormBox>
        <Typography color="textPrimary" variant="h4" component="h1" className={"FormTitle"}>Account Login</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <form name="loginform" onSubmit={handleSubmit}>
              <FormControl component="div" margin="normal" fullWidth={true}>
                <TextField
                  autoComplete="usename"
                  name="username"
                  id="username"
                  label="email"
                  variant="outlined"
                  onChange={handleUsernameChange}
                  defaultValue={username}
                  error={errorMessage && errorMessage.authentication ? true : false}
                  helperText={submitted && !username ? 'Email is required' : ''}
                />
              </FormControl>
              <FormControl component="div" margin="normal" fullWidth={true}>
                <TextField
                  autoComplete="password"
                  type="password"
                  name="password"
                  id="password"
                  label="password"
                  variant="outlined"
                  onChange={handlePasswordChange}
                  defaultValue={password}
                  error={errorMessage && errorMessage.authentication ? true : false}
                  helperText={submitted && !password ? 'Password is required': ''}
                />
              </FormControl>
                <div className={'UserActionButtons'}>
                  <FormControl component="div" margin="normal">
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      size='medium'
                    >Login</Button>
                  </FormControl>
                </div>
            </form>
          </Grid>
          <Grid item className={"LoginInfo"} xs={12} sm={6}>
            <Typography color="textSecondary" variant="h6" component="h2" className={'HelpText'}>
            Please login to view the dashboard for your property. If you do not have an account, please contact your account manager so they can create one for you.
            <br /><br />
            Forgot your password? <Link href='#' onClick={handleResetPasswordOpen} underline="hover">Request a password reset.</Link>
            </Typography>
            <Dialog
              open={resetPasswordOpen}
              onClose={handleResetPasswordClose}
              scroll='paper'
            >
              <DialogTitle id="resetpassword-modal-title">Send Reset Password</DialogTitle>
              <DialogContent dividers={false}>
                <DialogContentText>Please enter your email address below and we will send you an email with a link to reset your password.</DialogContentText>
                <TextField
                  autoFocus
                  margin='dense'
                  name='resetPasswordAddress'
                  id='resetPasswordAddress'
                  label='Email Address'
                  type='email'
                  error={errorMessage?.resetPassword || false}
                  helperText={errorMessage?.resetPassword}
                  fullWidth
                  defaultValue={username}
                  onChange={handleResetPasswordChange}
                  onKeyDown={(e) => { if (e.keyCode === 13) { handleResetPassword(); } }} // Enter key event to submit the form
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleResetPassword} color='primary'>Reset Email</Button>
              </DialogActions>
            </Dialog>
            <Typography color="textSecondary" variant="h6" component="h2">
              Need an account? <Link href='#' onClick={ () => setRequestAccountOpen(true) } underline="hover">Request an account.</Link>
            <Dialog
              open={requestAccountOpen}
              onClose={ () => setRequestAccountOpen(false) }
              scroll='paper'
            >
              <DialogTitle id="requestaccount-modal-title">Request a new account</DialogTitle>
              <DialogContent dividers={false}>
                <DialogContentText sx={{marginBottom: '4px'}}>Please fill out the form below and we will contact you shortly regarding your account.</DialogContentText>
                <TextField
                  autoFocus
                  margin='dense'
                  name='requestAccountName'
                  id='requestAccountName'
                  label='Your Name'
                  type='text'
                  error={errorMessage.requestAccount || false}
                  helperText={errorMessage.requestAccount}
                  fullWidth
                  onChange={handleRequestAccountChange}
                  onKeyDown={(e) => { if (e.keyCode === 13) { handleRequestAccount(); } }} // Enter key event to submit the form
                />
                <TextField
                  margin='dense'
                  name='requestAccountAddress'
                  id='requestAccountAddress'
                  label='Email Address'
                  type='email'
                  error={errorMessage.requestAccount || false}
                  helperText={errorMessage.requestAccount}
                  fullWidth
                  onChange={handleRequestAccountChange}
                  onKeyDown={(e) => { if (e.keyCode === 13) { handleRequestAccount(); } }} // Enter key event to submit the form
                />
                <TextField
                  margin='dense'
                  name='requestAccountProperty'
                  id='requestAccountProperty'
                  label='Property Name'
                  type='text'
                  error={errorMessage.requestAccount || false}
                  helperText={errorMessage.requestAccount}
                  fullWidth
                  onChange={handleRequestAccountChange}
                  onKeyDown={(e) => { if (e.keyCode === 13) { handleRequestAccount(); } }} // Enter key event to submit the form
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleRequestAccount} color='primary'>Request Account</Button>
              </DialogActions>
            </Dialog>


            </Typography>
          </Grid>
        </Grid>
      </LoginFormBox>
      <ContactInfoBox bgcolor="primary.main">
        <Typography color="textSecondary" variant="h6" component="h3" className={'ContactInfo'}>
        For any Internet service questions, Please call 1-877-275-9444 provided by Ovation Networks Inc. • <Link href="#" onClick={handlePrivacyOpen} underline="hover">privacy</Link>
        </Typography>
          <Dialog
            open={privacyOpen}
            onClose={handlePrivacyClose}
            scroll='paper'
          >
            <DialogTitle id="transition-modal-title">Ovation Networks, Inc.</DialogTitle>
            <DialogContent>
              <div>
                <Typography variant="h4">Privacy Policy and Data Privacy Policy</Typography>
                <div>Ovation Networks, Inc. is committed to ensuring that your privacy is protected. Should we ask you to provide certain information by which you can be identified when using our services, then you can be assured that it will only be used in accordance with this privacy statement.<br />
                This Privacy Policy provides an explanation as to what happens to any personal data that you provide to us, or that we collect from you.<br />
                We may change this policy from time to time by updating this page. You should check this page from time to time to ensure that you are happy with any changes. This policy is effective from January 2012.<br /></div>
                <h2>Information We Collect</h2>
                <ol>
                <li>Details of your visits to our login pages, websites and the resources that you access, including, but not limited to, traffic data, location data, weblogs and other communication data.</li>
                <li>Information that you provide by filling in forms on our logins, or additionally such as when you signup to our newsletter, register for information or make a purchase from Ovation or our partners.</li>
                <li>Information provided to us when you communicate with us for any reason.</li>
                </ol><br />
                <h2>Use of Cookies</h2>
                On occasion, we may gather information about your computer for our services and to provide statistical information regarding the use of our website to our advertisers and business partners. Such information will not identify you personally it is statistical data about our visitors and their use of our services. This statistical data does not identify any personal details whatsoever.<br />
                Similarly to the above, we may gather information about your general internet use by using a cookie file. A cookie is a small file which asks permission to be placed on your computer's hard drive. Once you agree, the file is added and the cookie helps analyze web traffic or lets you know when you visit a particular site. Cookies allow web applications to respond to you as an individual. The web application can tailor its operations to your needs, likes and dislikes by gathering and remembering information about your preferences.<br />
                All computers have the ability to automatically accept or decline cookies. This can be done by activating the setting on your browser which determines how to deal with cookies. Please note that should you choose to decline cookies, you may be unable to access particular parts of our website.<br />
                Our advertisers may also use cookies, over which we have no control. Such cookies (if used) would be downloaded once you click on advertisements while using our services, and/ or at the invocation of an advertisers ad code, and/ or at the opening of a webpage.<br />
                <h2>Use of Your Information</h2>
                The information that we collect and store relating to you is primarily used to enable us to provide our services to you. In addition, we may use the information for the following purposes:<br />
                <ol>
                <li>To provide you with information requested from us, relating to our products or services. To provide information on other products which we feel may be of interest to you, where you have consented to receive such information.</li>
                <li>To meet our contractual commitments to you.</li>
                <li>To notify you about any changes to our website, such as improvements or service/product changes, that may affect our service.</li>
                </ol><br />
                If you are an existing customer, we may contact you with information about various goods and services related to the services we have provided to you.<br />
                Further, we may use your data so that you can be provided with information about unrelated goods and services which we consider may be of interest to you. We may contact you about these goods and services by any of the methods that you consented at the time your information was collected.<br />
                If you are a new customer, we will only contact you or allow third parties to contact you only when you have provided consent and only by those means you provided consent for.<br />
                If you do not want us to use your data for our or third parties you will have the opportunity to withhold your consent to this when you provide your details to us on the form on which we collect your data.<br />
                Please be advised that we do not reveal information about identifiable individuals to our advertisers but we may, on occasion, provide them with aggregate statistical information about our visitors.<br />
                <h2>Storing Your Personal Data</h2>
                Data that is provided to us is stored on our secure servers. Details relating to any transactions entered into on our website will be encrypted to ensure its safety.<br />
                The transmission of information via the internet is not completely secure and therefore we cannot guarantee the security of data sent to us electronically and transmission of such data is therefore entirely at your own risk. Where we have given you (or where you have chosen) a password so that you can access certain parts of our site or use of our services, you are responsible for keeping this password confidential.<br />
                <h2>Disclosing Your Information</h2>
                Where applicable, we may disclose your personal information to any member of our group. This includes, where applicable, our subsidiaries, our holding company and its other subsidiaries (if any).<br />
                We may also disclose your personal information to third parties:<br />
                <ol>
                <li>Where we sell any or all of our business and/or our assets to a third party.</li>
                <li>Where we are legally required to disclose your information.</li>
                <li>To assist fraud protection and minimize credit risk.</li>
                <li>To assist in informing you of related or unrelated goods and services.</li>
                </ol>
                <h2>Third Party Links</h2>
                You might find links to third party websites on our website. These websites should have their own privacy policies which you should check. We do not accept any responsibility or liability for their policies whatsoever as we have no control over them.<br />
                <h2>Access To Information</h2>
                Should you wish to receive details that we hold about you please contact us using the contact details below.<br />
                <h2>Contacting Us</h2>
                We welcome any queries, comments or requests you may have regarding this Privacy Policy. Please do not hesitate to contact us at privacypolicy@ovationnetworks.com and / or 222 Third Ave SE, Suite 276, Cedar Rapids, IA 52401.<br />
                <h2>Your Consent</h2>
                By using Ovation Networks’ services, you consent to the collection and use of information as outlined in the above documentation. If we decide to change our privacy policy, we will post those changes on this page so that you are always aware of what information we collect, how we use it, and under what circumstances we disclose it.<br />
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={handlePrivacyClose} color='primary'>Close</Button>
            </DialogActions>
          </Dialog>
      </ContactInfoBox>
    </Box>
  );
}

export default Login
