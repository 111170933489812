import React from 'react';

import { alpha } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';

const useStyles = makeStyles((theme) => ({
  section: {
    display: 'flex',
    flexFlow: 'column',
    height: 'auto',
    marginTop: theme.spacing(3),
  },
  sectionTitle: {
    flex: '0 1 100%'
  },
  sectionContent: {
    boxShadow: "0 8px 10px -12px rgba(0,0,0,0.3)",
    flex: '1 1 100%',
    alignItems: 'stretch',
    height: '100%',
    padding: theme.spacing(2),
  },
}));

const Section = (props) =>  {
  const classes = useStyles();
  const hasHeader = (props.title || props.toolbar);

  return (
    <Grid item {...props} className={classes.section} >
      {hasHeader &&
        <Toolbar>
          <Typography variant="h5" color="primary" className={classes.sectionTitle}>
            {props.title}
          </Typography>
            {props.toolbar}
          </Toolbar>
      }
      <Paper className={classes.sectionContent}>
        {props.children}
      </Paper>
    </Grid>
  );
}

export default Section
