import { call, put, throttle, takeEvery } from 'redux-saga/effects';
import { createAction } from '../../helpers/actions'
import { serviceCall } from '../../helpers/effects';

import {
  BANDWIDTH_FETCH,
} from '../constants';
import { zabbixServices } from '../services';

export const zabbixActions = {
  bandwidthFetchRequest: (companyId) => createAction(BANDWIDTH_FETCH.REQUEST, { companyId: companyId }),
  bandwidthFetchSuccess: (bandwidth) => createAction(BANDWIDTH_FETCH.SUCCESS, { bandwidth: bandwidth }),
  bandwidthFetchFailure: (errorMessage) => createAction(BANDWIDTH_FETCH.FAILURE, { errorMessage: errorMessage }),
}

export function* fetchBandwidth(action) {
  try {
    const bandwidth = yield serviceCall(zabbixServices.fetchBandwidth, action.companyId);
    yield put(zabbixActions.bandwidthFetchSuccess(bandwidth));
  } catch (e) {
    yield put(zabbixActions.bandwidthFetchFailure(e.message));
  }
}

export const zabbixSagas = [
  //takeEvery(TICKETS_FETCH.REQUEST, fetchTickets)
  throttle(1000, BANDWIDTH_FETCH.REQUEST, fetchBandwidth),
]
