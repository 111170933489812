import _ from 'lodash';

export const createErrorMessageSelector = (actions) => (state) => {
  return _(actions)
    .map((action) => _.get(state, `errorMessage.${action}`))
    .compact().first() || '';
};

export const createLoadingSelector = (actions) => (state) => {
  return _(actions)
    .some((action) => {
      return _.get(state, `isLoading.${action}`);
    })
};

