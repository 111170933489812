import { combineReducers } from 'redux';

import { authentication } from './authentication.reducer';
//import { registration } from './registration.reducer';
import { alert } from './alert.reducer';
import { companies } from './companies.reducer';
import { company } from './company.reducers';
import { contactRequests } from './contact-requests.reducers';
import { helpdesk } from './helpdesk.reducer';
import { hotspot } from './hotspot.reducer'
import { users } from './users.reducer';
import { user } from './user.reducer';
import { zabbix } from './zabbix.reducer';


export const errorMessage = (state = {}, action) => {
  const { type, errorMessage } = action;
  const matches = /(.*)_(REQUEST|FAILURE)/.exec(type);

  if (!matches) return state;

  const [, requestName, requestState] = matches;

  return {
    ...state,
    [requestName]: requestState === 'FAILURE' ? errorMessage : '',
  };
};

export const isLoading = (state = {}, action) => {
  // TODO:  Payload is never used.  Does it need to be here
  const { type } = action;
  const matches = /(.*)_(REQUEST|SUCCESS|FAILURE)/.exec(type);

  if (!matches) return state;

  const [, requestName, requestState] = matches;

  return {
    ...state,
    [requestName]: requestState === 'REQUEST',
  };
};


const rootReducer = combineReducers({
  authentication,
  //registration,
  alert,
  companies,
  company,
  contactRequests,
  helpdesk,
  hotspot,
  users,
  user,
  zabbix,
  isLoading,
  errorMessage,
});

export default rootReducer;
