import { HOTSPOT_USAGE_FETCH } from '../constants';

export function hotspot (state = {}, action) {
  switch (action.type) {
    case HOTSPOT_USAGE_FETCH.SUCCESS:
      return {
        ...state,
        usage: action.usage,
      };
    case HOTSPOT_USAGE_FETCH.REQUEST:
    case HOTSPOT_USAGE_FETCH.FAILURE:
    default:
      return state;
  }
}
