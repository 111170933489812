import { call, put, race, delay, take } from 'redux-saga/effects';

import { AUTHENTICATION_REAUTH } from '../redux/constants';
import { authenticationActions } from '../redux/actions'

export function* serviceCall(service, ...args) {
  try {
    return yield call(service, ...args);
  } catch (e) {
    if (e.name === "NotAuthenticated") {
      yield put(authenticationActions.reauthRequest())

      const { success, failure } = yield race({
        success: take(AUTHENTICATION_REAUTH.SUCCESS),
        failure: take(AUTHENTICATION_REAUTH.FAILURE)
      });

      if (success) {
        return yield call(service, ...args);
      }
    }
    throw e;
  }
}


