import React from 'react';
import {
  Paper,
  Typography
} from '@mui/material';

class Error404 extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      open: true
    }

    this.handleClose = this.handleClose.bind(this);
  }

  handleClose(){
    this.setState({open: false});
  }

  render(){
    return (
      <Paper className='page404'>
      <Typography variant='h4'>Oh no!!!</Typography>
      <Typography variant='h5'>404 | This page could not be found.</Typography>
      </Paper>
    );
  }
}

export default Error404;